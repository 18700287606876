import { h, Component } from 'preact'
import { css } from 'emotion'

export default class BigQuote extends Component {
	constructor() {
		super()
	}

	render() {
        const style = css`
            font-size: 50px;
            font-weight: lighter;
            text-align: center;
            letter-spacing: 0.01em;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0 40px;
            margin-top: 120px;
            @media (max-width: 500px) {
                letter-spacing: 0;
                font-size: 35px;
                padding: 0 20px;
                margin-top: 40px;
            }
            > p {
                margin: 0;
                line-height: 1em;
                max-width: 800px;
                & a {
                    text-decoration: none;
                    background-image: linear-gradient(to bottom,rgba(0,0,0,1) 50%,rgba(0,0,0,0) 50%);
                    background-repeat: repeat-x;
                    background-size: 2px 4px;
                    background-position: 0 0.93em;
                }
                @media (max-width: 500px) {
                    line-height: 1.15em;
                }
            }
        `

		return (
			<section class={ style } dangerouslySetInnerHTML={{__html:(this.props.text || '').replace(/<a href=/g, '<a target="_blank" href=')}}>

			</section>
		)
	}
}
