import Router from 'preact-router'
import { h, Component } from 'preact'
import Head from 'preact-head'
import { css } from 'emotion'
import utils from './utils'

import XButton from './XButton'
import InfoButton from './InfoButton'
import Nav from './Nav'
import Projects from './Projects'
import Project from './Project'
import Info from './Info'
import DelayUnmount from './DelayUnmount'
import Loader from './Loader'

import {eventBus} from './App'

if (module.hot) {
	require('preact/debug')
}

const projectFromID = (uid) => {
    return this.state.projects.find( project => uid === nextProps.projectID )
}

export default class DesktopLayout extends Component {
	constructor(props) {
		super(props)

        this.state.activeProject = this.props.content.projects[0]

        // This can either be 'nav', 'main', or 'info'
        this.state.activePanel = 'main'
        this.state.projectOpen = false
		this.state.uiColor = this.state.activeProject.uiColor
	}

    onFirstProjectLoad = () => {
        this.setState({
            loaded: true
        })
    }

    static getDerivedStateFromProps(props, state) {
        const newState = {}
        newState.projects = props.content.projects.map( (project, index) => {
            project.index = index

            if (props.projectID === project.uid) {
                newState.activeProject = project
            }

            return project
        })

        return newState
    }

    onMenuButtonClick() {
        if (this.state.activePanel === 'nav') {
            this.setState({
                activePanel: 'main'
            })
        } else {
            this.setState({
                activePanel: 'nav'
            })
        }
    }

    onInfoButtonClick() {
        if (this.state.activePanel === 'info') {
            this.setState({
                activePanel: 'main'
            })
        } else {
            this.setState({
                activePanel: 'info'
            })
        }
    }

    onWheel = (e) => {
        if (this.state.activePanel !== 'main' || this.state.projectOpen) return

        if (Math.abs(e.deltaY) < 10) return

        if (this.lastWheelEvent) {
            // if the last wheel event timestamp happened too recently, return
            if (e.timeStamp - this.lastWheelEvent.timeStamp < 1000) return
        }

        if (!this.state.scrolling) {
            // if the current scroll event delta is less than the last one, return
            if (this.lastWheelEvent && Math.abs(e.deltaY) < Math.abs(this.lastWheelEvent.deltaY)) return

            let targetIndex = e.deltaY > 0 ? this.state.activeProject.index + 1 : this.state.activeProject.index - 1

            // if this would scroll off the top or bottom, return
            if (targetIndex > this.state.projects.length - 1 || targetIndex < 0) return

            this.setState({
                activeProject: this.state.projects[targetIndex],
				uiColor: this.state.projects[targetIndex].uiColor,
                scrolling: true
            })

            clearTimeout(this.scrollTimer)

            this.scrollTimer = setTimeout(function() {
                this.setState({ scrolling: false })
                this.lastWheelEvent = null
            }.bind(this), 700)
        }

        this.lastWheelEvent = e
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.projectID !== this.state.activeProject) {
            if (nextProps.projectID) {
                this.setState({
                    activeProject: projectFromID(nextProps.uid),
                    projectOpen: true
                })
            } else {
                this.setState({
                    projectOpen: false
                })
            }
        }
    }

	componentDidUpdate(prevProps, prevState) {
		// If activeProject has changed
		if (prevState.activeProject !== this.state.activeProject &&
			// And if this is the last project
			this.state.activeProject === this.state.projects.slice(-2).pop() &&
			// And if all projects aren't already shown
			this.props.showAllProjects === false
		) {
			// Wait for scroll transition to finish before adding the rest of the projects
			setTimeout(() => eventBus.emit('showAllProjects'), 800)
		}
	}

    onRoute(e) {
        if (e.url !== '/') {
            // If nav is open:
            if (this.state.activePanel !== 'main') {
                // If the project is already active (but not open), close the panel quickly
                if (this.state.activeProject.uid === e.current.attributes.activeProject.uid) {
                    setTimeout( () => {
                        this.setState({
                            activePanel: 'main',
                        })
                    }, 300)
                } else {
                    setTimeout( () => {
                        this.setState({
                            activePanel: 'main',
                        })
                    }, 900)
                }
            }

            // Check if project is already visible
            const isVisible = this.props.content.projects.includes(e.current.attributes.activeProject)

            if (!isVisible) {
                eventBus.emit('showAllProjects')
            }

            // Immediately make new project active and open
            this.setState({
                activeProject: e.current.attributes.activeProject,
				uiColor: e.current.attributes.activeProject.uiColor,
                projectOpen: true
            })
        } else {
            this.setState({
                activePanel: 'main',
                projectOpen: false,
                uiColor: e.current.attributes.activeProject.uiColor
            })
        }

        if (this.state.projectOpen) {
            this.setState({
                uiColor: this.state.activeProject.uiColor
            })
        }
    }

    setButtonColor(color) {
        this.setState({
            uiColor: color
        })
    }

    closePanels() {
        this.setState({
            activePanel: 'main'
        })
    }

	render() {
        const xPosition = this.state.activePanel === 'nav' ? 420 :
            this.state.activePanel === 'info' ? -860 : 0

        const style = css`
            position: relative;
            transform: translateX(${ xPosition }px);
            transition: transform 0.45s ease-out;
            height: 100%;
            &::before {
                content: '';
                width: 1px;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                background: rgba(0,0,0,0.15);
                opacity: ${ this.state.activePanel === 'nav' ? 1 : 0 };
                z-index: 1;
            }
            &::after {
                content: '';
                width: 1px;
                height: 100%;
                position: absolute;
                right: 0;
                top: 0;
                background: rgba(0,0,0,0.15);
                opacity: ${ this.state.activePanel === 'info' ? 1 : 0 };
            }
        `

        const menuButtonStyle = css`
            position: absolute;
            left: 40px;
            top: 36px;
            z-index: 1;
        `

        const infoButtonStyle = css`
            position: absolute;
            right: 40px;
            bottom: 36px;
            font-size: 12px;
            letter-spacing: 0.06em;
            font-family: MicrogrammaStd-BoldExtended;
            color: white;
            text-shadow: ${this.state.uiColor === 'white' ? '0 0 3px rgba(0,0,0,0.2)' : 'none'};
            z-index: 1;
			color: ${this.state.uiColor};
            opacity: ${ this.state.projectOpen || this.state.activePanel === 'info' ? 0 : 1 };
            visibility: ${ this.state.projectOpen || this.state.activePanel === 'info' ? 'hidden' : 'visible' };
            transition: ${ this.state.projectOpen || this.state.activePanel === 'info' ? 'opacity 0.2s, visiblity 0s 0.2s' : 'visiblity 0s, opacity 0.2s 0.7s' };
        `

        const xButtonStyle = css`
            position: absolute;
            right: 40px;
            top: 36px;
            z-index: 1;
        `

        const navStyle = css`
            width: 420px;
            height: 100%;
            position: absolute;
            top: 0;
            right: 100%;
            z-index: 0;
            &::-webkit-scrollbar {
                display: none;
            }
        `

        const mainStyle = css`
            width: 100%;
            height: 100%;
        `

        const infoStyle = css`
            width: 860px;
            height: 100%;
            position: absolute;
            left: 100%;
            top: 0;
            z-index: 2;
        `

		const title = this.state.activeProject && this.state.projectOpen ?
			`${this.props.content.title} - ${this.state.activeProject.title}` :
			this.props.content.title

		return (
			<div class={ style } onwheel={ this.onWheel }>
				<Head>
					<title>{title}</title>
					<meta property="og:title" content={title}/>
					<meta name="twitter:title" content={title}/>
					<meta name="og:title" content={title}/>
				</Head>
                <Nav
                    class={ navStyle }
                    content={this.props.content}
                    projects={ this.props.content.projects }
                    activeProject={this.state.activeProject}
                    isOpen={ this.state.activePanel === 'nav' }
                    mediaType={'image'}
                />
                    <main class={ mainStyle }>
                        <XButton
                            class={ menuButtonStyle }
                            action={ this.state.activePanel === 'nav' ? 'close' : 'menu' }
                            onclick={ () => this.onMenuButtonClick() }
                            color={ this.state.uiColor }
                            shadow={ this.state.uiColor === 'white' ? '0 0 3px rgba(0,0,0,0.2)' : null}
                        />
                        <InfoButton
                            class={ infoButtonStyle }
                            onclick={ () => this.onInfoButtonClick() }
                            isHidden={ this.state.activePanel === 'info' || this.state.projectOpen }
                        />
                        <Router onChange={ (e) => this.onRoute(e) }>
                            <Projects
                                path={ '/' }
                                content={this.props.content}
                                activeProject={ this.state.activeProject }
                                panelIsActive={ this.state.activePanel === 'main' }
                                projects={ this.props.content.projects }
                                clicksBlocked={ this.state.activePanel !== 'main' }
                                onBlockedClick={ () => this.closePanels() }
                                projectOpen={ false }
                                onFirstProjectLoad={ this.onFirstProjectLoad }
                            />
                            { this.props.content.allProjects.map( project =>
                                <Projects
                                    path={ '/'+project.uid }
                                    content={this.props.content}
                                    activeProject={ project }
                                    projectOpen={ true }
                                    panelIsActive={ this.state.activePanel === 'main' }
                                    projects={ this.props.content.projects }
                                    clicksBlocked={ this.state.activePanel !== 'main' }
                                    onBlockedClick={ () => this.closePanels() }
                                    onProjectScroll={ color => this.setButtonColor(color) }
                                    hideInfoButton={ this.state.activePanel === 'info' }
                                    onFirstProjectLoad={ this.onFirstProjectLoad }
                                />
                            ) }
                        </Router>
                        { this.state.projectOpen || this.state.activePanel === 'info' ?
                            <XButton
                                class={ xButtonStyle }
                                action={ 'close' }
                                href={ this.state.projectOpen ? '/' : null }
                                onclick={ this.state.activePanel === 'info' ? () => this.closePanels() : null }
                                color={ this.state.uiColor }
                                shadow={ this.state.uiColor === 'white' ? '0 0 3px rgba(0,0,0,0.2)' : 'none'}
                            />
                        : null }
                    </main>
                <Info
                    {...this.props.content.info}
                    class={ infoStyle }
                    isOpen={ this.state.activePanel === 'info' }
                    onXButtonClick={ () => this.closePanels() }
                    isMobile={false}
                />
			</div>
		)
	}
}
