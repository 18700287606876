import { h, Component } from 'preact'
import { css, keyframes } from 'emotion'
import { TweenLite } from 'gsap'

import {eventBus} from './App'
import PhoneWrapper from './PhoneWrapper'
import BrowserBar from './BrowserBar'
import SquareButton from './SquareButton'

class ProjectPreview extends Component {
    componentDidMount() {
        if (this.props.mediaType === 'video') {
            this.observer = new IntersectionObserver(intersections => this.onVisibilityChange(intersections))
            this.observer.observe(this.element)
        }

        if (this.backgroundImage && this.backgroundImage.innerWidth !== 0) this.onBackgroundImageLoad()

        // PhoneWrapper provides a callback for its contents being loaded
        // If the project preview doesn't need a PhoneWrapper, we have to manage the child loading
        if (!this.usePhoneWrapper && this.child) {
            if (this.child.nodeName === 'IMG' && this.child.naturalWidth !== 0) {
                this.onChildLoad()
            } else if (this.child.nodeName === 'VIDEO' && this.child.videoWidth !== 0) {
                this.onChildLoad()
            }
        }

        this.checkIfLoaded()
    }

    onVisibilityChange(intersections) {
        const ratio = intersections[0].intersectionRatio
        if (ratio > 0) {
            this.setState({
                inView: true
            })
            if (this.child) this.child.play()
        } else {
            this.setState({
                inView: false
            })
            if (this.child) this.child.pause()
        }
    }

    onPhoneWrapperLoad = () => {
        this.setState({
            childLoaded: true
        })
        this.checkIfLoaded()
    }

    onBackgroundImageLoad = (e) => {
        this.setState({
            backgroundImageLoaded: true
        })
        this.checkIfLoaded()
    }

    onChildLoad = () => {
        this.setState({
            childLoaded: true
        })
        this.checkIfLoaded()
    }

    // check if everything is loaded
    checkIfLoaded = () => {
        if (this.props.usePhoneWrapper) { // use phoneWrapper
            if (!this.state.childLoaded) return
            if (!this.state.backgroundImageLoaded) return
            if (this.props.onload) this.props.onload()

        } else { // Don't use phoneWrapper
            if (this.state.childLoaded && this.props.onload) this.props.onload()
        }
    }

    render() {
        const projectThumbnailStyle = css`
            position: relative;
            width: 100%;
            height: ${this.props.usePhoneWrapper ? '250px' : 'auto'};
            display: flex;
            justify-content: center;
            align-items: center;
            background-size: cover;
            background-position: center;
            // To stop flickering on iOS
            transform: translate3d(0,0,0);
            background-image: ${this.props.backgroundImage ? `url(${this.props.backgroundImage})` : 'none'};
            background-color: #eee;
            > img, video {
                height: auto;
                width: 100%;
                z-index: 1;
                transform: translate3d(0,0,0);
            }
            > img.thumbnail {
                position: static;
            }
        `

        const phoneWrapperStyle = css`
            height: 85%;
            & video {
                height: 100% !important;
            }
            & img {
                height: 100%;
                overflow: hidden;
            }
        `

        const child = this.props.children[0]

        return (
            <div class={projectThumbnailStyle} ref={element => this.element = element}>
                {this.props.usePhoneWrapper &&
                    <img
                        src={this.props.backgroundImage}
                        ref={img => this.backgroundImage = img}
                        style={{width: 0,height:0}}
                        onload={this.onBackgroundImageLoad}
                    />
                }
                {this.props.usePhoneWrapper ? (
                    <PhoneWrapper
                        class={phoneWrapperStyle}
                        onload={this.onPhoneWrapperLoad}
                        playVideo={this.state.inView}
                    >
                        { child.nodeName === 'img' ?
                            <img
                                {...child.attributes}
                                ref={child => this.child = child}
                                onload={this.onChildLoad}
                            />
                        :
                            <video
                                {...child.attributes}
                                ref={child => this.child = child}
                                type="video/mp4"
                                playsinline
                                muted
                                onloadedmetadata={this.onChildLoad}
                                preload="metadata"
                                autoplay={ this.state.inView }
                            ></video>
                        }
                    </PhoneWrapper>
                ) : ( child.nodeName === 'img' ? (
                        <img
                            {...child.attributes}
                            ref={child => this.child = child}
                            onload={this.onChildLoad}
                        />
                    ) : (
                        <video
                            {...child.attributes}
                            ref={child => this.child = child}
                            type="video/mp4"
                            playsinline
                            muted
                            onloadedmetadata={this.onChildLoad}
                            preload="metadata"
                            autoPlay={ this.state.inView }
                        ></video>
                    )
                )}
            </div>
        )
    }
}

export default class Nav extends Component {
	constructor(props) {
		super(props)
        this.state.loadedProjects = this.props.loadedProjects || []
	}

    componentDidUpdate(prevProps, prevState) {
        if (this.props.projects !== prevProps.projects) {
            this.setState({
                allProjectsFetched: true
            })
        }

        if (prevProps.activeProject !== this.props.activeProject && this.props.isOpen) {
            this.centerActiveProject(true)
        }

        if (this.props.isOpen && !prevProps.isOpen) {
            this.centerActiveProject(false)
        }

        // If we receive new loaded projects
        if (this.props.loadedProjects && this.props.loadedProjects !== prevProps.loadedProjects) {
            this.setState({
                loadedProjects: this.props.loadedProjects
            })
            if (this.props.projects.length === this.state.loadedProjects.length) {
                if (this.props.onload) {
                    this.props.onload(this.state.loadedProjects)
                }
            }
        }
    }

    centerActiveProject(shouldAnimate) {
        if (this.props.isMobile) return
        const activeProjectIndex = this.props.projects.indexOf(this.props.activeProject)
        const activeProjectElement = this.element.querySelectorAll('div.project')[activeProjectIndex]
        if (!activeProjectElement || !this.element) return

        const scrollAmt = activeProjectElement.offsetTop - (window.innerHeight / 2) + (activeProjectElement.clientHeight / 2)

        if (shouldAnimate) {
            TweenLite.to( this.element, 1, {
                scrollTop: Math.min(Math.max(scrollAmt, 0), this.element.scrollHeight - this.element.clientHeight),
                ease: Power1.easeInOut
            })
        } else {
            this.element.scrollTop = scrollAmt
        }
    }

    onProjectThumbLoaded = (project) => {

        if (!this.state.loadedProjects.includes(project)) {
            this.setState({
                loadedProjects: [...this.state.loadedProjects, project]
            })
        }

        if (this.props.projects.length === this.state.loadedProjects.length) {
            if (this.props.onload) {
                this.props.onload(this.state.loadedProjects)
            }
        }
    }

    showAllProjects = () => {
        eventBus.emit('showAllProjects')
        this.setState({
            showAllProjects: true
        })
    }

	render() {
        const navStyle = css`
            background: white;
            padding: 30px 0;
            overflow-x: hidden;
            overflow-y: scroll;
            @media (max-width: 500px) {
                overflow: hidden;
            }
            & a.project-link {
                transition: opacity 0.2s;
                display: block;
                text-decoration: none;
            }
            & .project {
                color: black;
                padding: 0 30px 26px 30px;
                backface-visibility: hidden;
                transform: translate3d(0,0,0);
                @media (max-width: 500px) {
                    padding: 0 10px 26px;
                }
                & h4 {
                    font-size: 13px;
                    letter-spacing: 0.1em;
                    font-family: MicrogrammaStd-BoldExtended;
                    margin-top: 12px;
                    margin-bottom: 5px;
                    @media (max-width: 500px) {
                        margin-bottom: 6px;
                        font-size: 0.95rem;
                    }
                }
                & p {
                    margin-top: 0;
                    text-transform: uppercase;
                    letter-spacing: 0.15em;
                    font-size: 11px;
                }
            }
            &:hover .project-link {
                opacity: 0.6;
                // Also, this should be irrelevant, since you can't hover on mobile
                // but we actually need it for some reason
                @media (max-width: 500px) {
                    opacity: 1;
                }
            }
            & .project-link:hover {
                opacity: 1;
            }
        `
        const loadMoreButtonStyle = css`
            opacity: ${this.props.isOpen ? 1 : 0};
            transition: ${this.props.isOpen ? 'opacity 0.4s 0.4s' : 'opacity 0.4s'};
            @media (max-width: 500px) {
                display: flex;
                justify-content: center;
                margin-top: 20px;
                margin-bottom: 40px;
            }
            & button {
                margin-left: 30px;
                @media (max-width: 500px) {
                    margin-left: 10px;
                }
            }
        `

		return (
			<nav
                class={`${navStyle} ${this.props.class || ''}`}
                ref={element => this.element = element}
            >
                { this.props.projects.map( (project, index) => {
                    const fadeInProject = keyframes`
                        from {
                            opacity: 0;
                        }
                        to {
                            opacity: 1;
                        }
                    `
                    const staggeredFadeIn = css`
                        opacity: 1;
                        animation: ${fadeInProject} 0.4s both ${(this.props.fadeInDelay || 0) + (0.2 * index)}s;
                    `
                    const fadeOut = css`
                        animation: ${keyframes`
                            from {
                                opacity: 1;
                            }
                            to {
                                opacity: 0;
                            }
                        `} 0.4s both;
                    `
                    const notLoaded = css`
                        opacity: 0;
                    `

                    // If any project with a lower index than current doesn't exist in state.loadedProjects,
                    // do not animate in yet.
                    const thumbnailLoaded = this.props.projects.slice(index).find(earlierProject => {
                        // Check if earlier project exists in state.loadedProjects
                        return this.state.loadedProjects.find(project => project.uid === earlierProject.uid)
                    }) !== undefined

                    return (
                        <a href={ '/'+project.uid } class="project-link">
                            <div
                                class={[
                                    'project',
                                    thumbnailLoaded ?
                                        this.props.isOpen ?
                                            staggeredFadeIn : fadeOut
                                    : notLoaded
                                ].join(' ')}
                            >
                                { !project.isMobile ?
                                    <BrowserBar />
                                : null }
                                <ProjectPreview
                                    project={project}
                                    usePhoneWrapper={project.isMobile}
                                    backgroundImage={project.isMobile ? project.backgroundImage : null}
                                    mediaType={this.props.mediaType}
                                    playVideo={this.state.inView}
                                    onload={() => this.onProjectThumbLoaded(project)}
                                >
                                    { this.props.mediaType === 'image' ? (
                                            <img class="thumbnail"
                                                src={ project.heroVideoPoster }
                                            />
                                        ) : (
                                            <video
                                                class="thumbnail"
                                                src={ project.heroVideo }
                                                type="video/mp4"
                                                poster={ project.heroVideoPoster }
                                                preload="metadata"
                                                muted
                                                loop
                                                playsinline
                                            ></video>
                                        )
                                    }
                                </ProjectPreview>
                                <h4>{ project.title }</h4>
                                <p>{ project.tags.join(', ') }</p>
                            </div>
                        </a>
                    )
                }) }
                { this.props.content.projects.length !== this.props.content.allProjects.length &&
                    <div class={loadMoreButtonStyle}>
                        <SquareButton onclick={this.showAllProjects}>
                            { 'LOAD MORE PROJECTS' }
                        </SquareButton>
                    </div>
                }
			</nav>
		)
	}
}
