import { h, Component } from 'preact'
import { css } from 'emotion'

export default class XButton extends Component {
    constructor() {
        super()
    }

    render() {
        const style = css`
            position: absolute;
            display: block;
            width: 41px;
            height: 41px;
            cursor: pointer;
            @media (max-width: 500px) {
                width: 30px;
                height: 30px;
            }
            > div {
                width: 100%;
                height: 100%;
            }
            & div > div {
                position: absolute;
                width: 41px;
                height: 2px;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                margin: auto;
                background: ${ this.props.color || 'white' };
                box-shadow: ${ this.props.shadow || 'none'};
                transition: transform 0.2s, opacity 0.1s, background 0.1s;
                @media (max-width: 500px) {
                    width: 30px;
                }
            }
            & #one {
                transform: ${ this.props.action === 'menu' ? `translateY(-10px)` : `translateY(0) rotate(-45deg)` }
            }
            & #two {
                opacity: ${ this.props.action === 'menu' ? 1 : 0 }
            }
            & #three {
                transform: ${ this.props.action === 'menu' ? `translateY(10px)` : `translateY(0) rotate(45deg)` }
            }
        `

        return (
            <a href={this.props.href} class={`${style} ${this.props.class}`}>
                <div onclick={ this.props.onclick ? (e) => this.props.onclick(e) : null } >
                    <div id="one"></div>
                    <div id="two"></div>
                    <div id="three"></div>
                </div>
            </a>
        )
    }
}
