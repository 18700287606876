import {h} from 'preact'
import {css} from 'emotion'

export default () => {
    const browserBarStyle = css`
        display: block;
        height: 14px;
        display: flex;
        align-items: center;
        padding-left: 6px;
        background-image: linear-gradient(#EBEBEB, #D4D4D4);
        border-bottom: 0.5px solid #C3C3C3;
        border-radius: 3px 3px 0px 0px;
        & div {
            width: 4px;
            height: 4px;
            margin-right: 3px;
            border-radius: 100%;
        }
        & .red {
            background: #DB6158;
            border: 1px solid #C74744;
        }
        & .yellow {
            background: #F1B942;
            border: 1px solid #D4A033;
        }
        & .green {
            background: #78C64C;
            border: 1px solid #69A937;
        }
    `

    return (
        <div class={browserBarStyle}>
            <div class="red"></div>
            <div class="yellow"></div>
            <div class="green"></div>
        </div>
    )
}
