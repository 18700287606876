import { h, Component } from 'preact'
import { css } from 'emotion'

import ClickToCopy from './ClickToCopy'

export const ListSectionStyle = css`
    width: 100%;
    flex-grow: 1;
    & h3 {
        font-family: MicrogrammaStd-BoldExtended;
        text-transform: uppercase;
        font-size: 11px;
        letter-spacing: 0.1em;
        margin-top: 0;
        margin-bottom: 29px;
    }
    & ul {
        list-style-type: none;
        margin: 0;
        padding-left: 20px;
        line-height: 1.81;
        text-transform: uppercase;
        font-size: 11px;
        letter-spacing: 0.1em;
        @media(max-width: 500px) {
            padding-right: 20px;
            padding-left: 10px;
            font-size: .75rem;
            line-height: 1.9;
        }
        & li {
            transition: opacity 0.2s;
        }
        & a {
            -webkit-tap-highlight-color: transparent;
            user-select: none;
            color: black;
            text-decoration: none;
            li {
                cursor: pointer;
            }
        }
    }
`

const linkHoveredStyle = css`
    & ul {
        & li {
            opacity: 0.6;
        }
        & a:hover li {
            opacity: 1;
        }
    }
`

export default class ListSection extends Component {
    onLinkMouseEnter = () => {
        this.setState({
            linkHovered: true
        })
    }

    onLinkMouseLeave = () => {
        this.setState({
            linkHovered: false
        })
    }

    render () {
        const isMobile = window.matchMedia('(max-width: 500px)').matches
        return (
            <section class={ `ListSection ${ListSectionStyle} ${this.props.className || ''} ${this.state.linkHovered ? linkHoveredStyle : ''}` }>
                { this.props.title ?
                    <h3>{ this.props.title }</h3>
                : null }
                <ul>
                    { this.props.items.map( item => {
                        if (item.url) {
                            if (!isMobile && item.url.indexOf('mailto:') === 0) {
                                const value = item.url.substr(7)
                                const text = item.text || value
                                return (
                                    <ClickToCopy
                                        onmouseenter={this.onLinkMouseEnter}
                                        onmouseleave={this.onLinkMouseLeave}
                                        value={value}
                                    >
                                        <li>{text}</li>
                                    </ClickToCopy>
                                )
                            } else {
                                return (
                                    <a
                                        href={ item.url }
                                        target="_blank"
                                        onmouseenter={!isMobile && this.onLinkMouseEnter}
                                        onmouseleave={!isMobile && this.onLinkMouseLeave}
                                        ontouchstart={isMobile && this.onLinkMouseEnter}
                                        ontouchend={isMobile && this.onLinkMouseLeave}
                                        ontouchcancel={isMobile && this.onLinkMouseLeave}
                                    >
                                        <li dangerouslySetInnerHTML={{__html: item.text}}></li>
                                    </a>
                                )
                            }
                        } else {
                            return <li dangerouslySetInnerHTML={{__html: item.text}}></li>
                        }
                    }) }
                </ul>
            </section>
        )
    }
}
