import { h, Component } from 'preact'
import { css } from 'emotion'
import utils from '../utils'
import MuteButton from './MuteButton'
import innerHeight from 'ios-inner-height'


export default class BigImage extends Component {
  componentDidMount() {
    if (this.image.complete && this.image.naturalWidth !== 0) this.setState({loaded: true})
  }

	render() {
        const bigImageStyle = css`
            padding: 0 100px;
            margin-top: 120px;
            display: flex;
            justify-content: center;
            @media (max-width: 500px) {
                padding: 0 20px;
                margin-top: 40px;
            }
            > .content {
                position: relative;
                max-width: 1400px;
                & img {
                    display: block;
                    width: 100%;
                    background: #eee;
                    position: relative;
                    opacity: ${this.state.loaded ? 1 : 0};
                    max-height: 900px;
                    @media (max-width: 1399px) {
                        max-height: 750px;
                    }
                    @media (max-width: 500px) {
                        width: auto;
                        max-width: calc(100% + 1px);
                        max-height: ${innerHeight() * .7}px;
                    }
                }
                & .caption {
                    font-size: 11px;
                    text-transform: uppercase;
                    letter-spacing: 0.15em;
                    margin-top: 32px;
                    text-align: right;
                }
                & .caption.mobile {
                    text-align: center;
                    margin-top: 0;
                    margin-bottom: 20px;
                    hyphens: auto;
                }
            }
            > .content.show-browser-chrome {
                & .image-wrapper {
                    border-radius: 6px 6px 0 0;
                    overflow: hidden;
                    box-shadow: 0 0 20px 10px rgba(0,0,0,0.1);
                    border: 1px solid #C3C3C3;
                }
                & .browser-bar {
                    content: '';
                    height: 46px;
                    display: flex;
                    align-items: center;
                    padding-left: 16px;
                    background-image: linear-gradient(#EBEBEB, #D4D4D4);
                    & div {
                        width: 12px;
                        height: 12px;
                        margin-right: 10px;
                        border-radius: 100%;
                    }
                    & .red {
                        background: #DB6158;
                        border: 1px solid #C74744;
                    }
                    & .yellow {
                        background: #F1B942;
                        border: 1px solid #D4A033;
                    }
                    & .green {
                        background: #78C64C;
                        border: 1px solid #69A937;
                    }
                    @media (max-width: 500px) {
                        content: '';
                        height: 14px;
                        padding-left: 6px;
                        border-bottom: 0.5px solid #C3C3C3;
                        & div {
                            width: 4px;
                            height: 4px;
                            margin-right: 3px;
                        }
                    }
                }
            }
        `

        const isMobile = window.matchMedia('(max-width: 500px)').matches

		return (
			<section class={ bigImageStyle }>
                <div class={ 'content' + (this.props.showBrowserChrome ? ' show-browser-chrome' : '') } >
                    {isMobile && (
                        <footer>
                            <div class="caption mobile" dangerouslySetInnerHTML={{__html:(this.props.caption || '').replace(/<a href=/g, '<a target="_blank" href=')}}>
                            </div>
                        </footer>
                    )}
                    <div class="image-wrapper">
                        { this.props.showBrowserChrome ?
                            <div class="browser-bar">
                                <div class="red"></div>
                                <div class="yellow"></div>
                                <div class="green"></div>
                            </div>
                        : null }
                        <img
                            ref={image => this.image = image}
                            src={this.props.url}
                            alt={this.props.caption}
                            onload={()=>this.setState({loaded: true})}
                        />
                    </div>
                    {!isMobile && (
                        <footer>
                            <div class="caption" dangerouslySetInnerHTML={{__html:(this.props.caption || '').replace(/<a href=/g, '<a target="_blank" href=')}}>
                            </div>
                        </footer>
                    )}
                </div>
			</section>
		)
	}
}
