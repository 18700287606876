import { h, Component } from 'preact'
import { css } from 'emotion'

export default class InfoButton extends Component {
    render() {
        const style = css`
            position: absolute;
            letter-spacing: 0.06em;
            font-family: MicrogrammaStd-BoldExtended;
            color: white;
            z-index: 1;
            cursor: pointer;
            -webkit-font-smoothing: antialiased;
        `

        return (
            <a class={`${style} ${this.props.class}`} onclick={ (e) => this.props.onclick(e) } >
                INFO
            </a>
        )
    }
}
