import { h, Component } from 'preact'
import { css, keyframes } from 'emotion'
import { TweenLite } from 'gsap'
import utils from './utils'

import PhoneWrapper from './PhoneWrapper'
import BigQuote from './project/BigQuote'
import Caption from './project/Caption'
import Carousel from './project/Carousel'
import StaticCarousel from './project/StaticCarousel'
import TextBlock from './project/TextBlock'
import BigVideo from './project/BigVideo'
import BigImage from './project/BigImage'
import CTAs from './project/CTAs'
import Footer from './project/Footer'
import SiteFooter from './project/SiteFooter'

export default class ProjectBody extends Component {
    componentDidMount() {
        document.querySelectorAll('video[autoplay]').forEach(video => {
            video.play().catch(() => {})
        })
    }

    scrollToTop() {
        TweenLite.to( this.props.scrollingParent || this.div, 1, {
            scrollTop: 0,
            ease: Power2.easeInOut
        })
    }

    shouldComponentUpdate(nextProps) {
        return nextProps !== this.props
    }

	render() {
        const style = css`
            width: 100%;
        `

        const fadeIn = keyframes`
            from {
                opacity: 0;
            }
            to {
                opacity: 1;
            }
        `

        const fadeOut = keyframes`
            from {
                opacity: 1;
            }
            to {
                opacity: 0;
            }
        `

        const projectBodyStyle = css`
            width: 100%;
            background: white;
            color: black;
            overflow-x: hidden;
            overflow-y: hidden;
            > header {
                height: 80px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 23px 40px;
                margin-bottom: 80px;
                @media (max-width: 500px) {
                    height: auto;
                    min-height: 80px;
                    padding: 18px 10px;
                    margin-bottom: 0;
                }
                > h2 {
                    font-family: MicrogrammaStd-BoldExtended;
                    text-transform: uppercase;
                    font-size: 30px;
                    line-height: 1.04;
                    margin: 0;
                    @media (max-width: 500px) {
                        max-width: calc(100% - 48px);
                        font-size: 26px;
                    }
                }
                > p {
                    margin: 0;
                    font-size: 11px;
                    letter-spacing: 0.15em;
                    text-transform: uppercase;
                    @media (max-width: 500px) {
                        display: none;
                    }
                }
            }
            > .hero-with-phone {
                width: 100%;
                margin: 20px 0;
                height: 80vh;
                display: flex;
                justify-content: center;
                align-items: center;
                background-size: cover;
                background-position: center;
                @media (max-width: 500px) {
                    margin: 0;
                }
            }
            > video.hero {
                display: block;
                width: 100%;
                padding: 20px 0;
                @media(max-width: 500px) {
                    padding: 0;
                    // Fix strange white pixel column on either side
                    width: calc(100% + 1px);
                }
            }
            > * {
                animation: ${ this.props.isOpen ? `${ fadeIn } 0.3s both 0.3s` : `${ fadeOut } 0.3s both` };
            }
            @media(max-width: 500px) {
                > div.sections {
                    animation: ${keyframes`
                        from {
                            opacity: 0;
                            transform: translate3D(0,20px,0);
                        }
                        to {
                            opacity: 1;
                            transform: translate3D(0,0,0);
                        }
                    `} 0.4s both 0.4s;
                }
            }
        `

        const phoneWrapperStyle = css`
            height: calc(100% - 50px);
        `

		return (
			<div
                class={ style+' '+(this.props.class || '') }
                onscroll={ e => this.props.onscroll(e) }
                ref={ div => this.div = div}
            >
                <article class={ projectBodyStyle }>
                    <header>
                        <h2>{ this.props.title }</h2>
                        <p>{ this.props.tags.join(', ') }</p>
                    </header>

                    { this.props.browserIsMobile ?
                        this.props.isMobile ?
                            <section class="hero-with-phone" style={{backgroundImage: `url(${this.props.backgroundImage})`}}>
                                <PhoneWrapper class={phoneWrapperStyle}>
                                    <video
                                        src={ this.props.heroVideo }
                                        poster={ this.props.heroVideoPoster }
                                        autoPlay
                                        muted
                                        loop
                                        playsinline
                                    />
                                </PhoneWrapper>
                            </section>
                        :
                        <video
                            class="hero"
                            src={ this.props.heroVideo }
                            poster={ this.props.heroVideoPoster }
                            autoPlay
                            muted
                            loop
                            playsinline
                        />
                    : null }
                    <div class="sections">
                        { this.props.sections.map((block, index) => {
                            if (!block) return null

                            switch (block.type) {
                                case 'BigQuote':
                                    return <BigQuote {...block} />
                                    break

                                case 'Caption':
                                    return <Caption {...block} />
                                    break

                                case 'Carousel':
                                    // If carousel should be a static row on desktop and we are on desktop
                                    if (!this.props.browserIsMobile && block.disableOnDesktop) {
                                        return <StaticCarousel {...block} />
                                    } else {
                                        return <Carousel {...block} />
                                    }
                                    break

                                case 'VideoCarousel':
                                    // If carousel should be a static row on desktop and we are on desktop
                                    if (!this.props.browserIsMobile && block.disableOnDesktop) {
                                        return <StaticCarousel {...block} />
                                    } else {
                                        return <Carousel {...block} />
                                    }
                                    break

                                case 'TextBlock':
                                    return <TextBlock {...block} />
                                    break

                                case 'BigVideo':
                                    return <BigVideo {...block} />
                                    break

                                case 'BigImage':
                                    return <BigImage {...block} />
                                    break
                            }
                        } ) }
                    </div>
                    <CTAs
                        CTA={this.props.CTA}
                        onClickScrollToTop={ () => this.scrollToTop() }
                    />
                    <Footer
                        sections={ this.props.footerSections }
                    />
                </article>
                <SiteFooter about={this.props.content.info.footerAbout} sections={this.props.content.info.footerSections}/>
			</div>
		)
	}
}
