import { Router, route } from 'preact-router'
import Match from 'preact-router/match'
import Head from 'preact-head'
import { h, Component } from 'preact'
import { css, keyframes } from 'emotion'
import utils from './utils'

import XButton from './XButton'
import SquareButton from './SquareButton'
import Nav from './Nav'
import Projects from './Projects'
import ProjectBody from './ProjectBody'
import Info from './Info'
import SiteFooter from './project/SiteFooter'
import DelayUnmount from './DelayUnmount'
import Loader from './Loader'

import { eventBus } from './App'

if (module.hot) {
	require('preact/debug')
}

class TransitionWrapper extends Component {
    componentDidMount() {
        if (this.props.mountDelay && this.props.mount) {
            this.state.mount = false
            this.state.render = false
            this.timer = setTimeout(() => {
                this.setState({
                    mount: true,
                    render: true
                })
            }, this.props.mountDelay)
        } else {
            this.setState({
                mount: this.props.mount,
                render: this.props.mount
            })
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.mount && !nextProps.mount) {
            clearTimeout(this.timer)
            if (this.props.animate === false) {
                this.setState({
                    render: false,
                    mount: false
                })
            } else {
                this.setState({ render: false })
                if (this.props.onExit) this.props.onExit()
                this.timer = setTimeout(() => {
                    this.setState({ mount: false })
                    if (this.props.onUnmount) this.props.onUnmount()
                }, this.props.unmountDelay ? this.props.unmountDelay : 400)
            }

        } else if (!this.props.mount && nextProps.mount) {
            clearTimeout(this.timer)
            if (this.props.animate === false) {
                this.setState({
                    render: true,
                    mount: true
                })
            } else {
                this.timer = setTimeout(() => {
                    this.setState({ mount: true, render: true })
                    if (this.props.onMount) this.props.onMount()
                }, this.props.mountDelay ? this.props.mountDelay : 400)
            }
        }
    }

    render() {
        const enter = keyframes`
            0% {
                opacity: 0;
                transform: translate3D(0,20px,0);
            }
            100% {
                opacity: 1;
                transform: translate3D(0,0,0);
            }
        `
        const exit = keyframes`
            0% {
                opacity: 1;
            }
            100% {
                opacity: 0;
            }
        `

        const enterStyle = this.props.enterStyle || css`
            animation: ${this.props.enterKeyframes || enter} ${this.props.animate === false ? 0 : this.props.enterDuration || 0.4}s both;
        `
        const exitStyle = this.props.exitStyle || css`
            animation: ${this.props.exitKeyframes || exit} ${this.props.animate === false ? 0 : this.props.exitDuration || 0.4}s both;
        `

        return this.state.mount ? (
            <div class={this.state.render ? enterStyle : exitStyle }>
                { this.props.children }
            </div>
        ) : null
    }
}



export default class MobileLayout extends Component {
	constructor(props) {
		super(props)

        this.state.activeProject = this.props.content.projects[0]

        // This can either be 'main' or 'info' or 'project'
        this.state.activePanel = 'main'
        this.state.projectOpen = false
        this.state.uiColor = 'white'
	}

    componentDidMount() {
        eventBus.on('openInfoPanel', () => {
            this.setState({
                projectOpen: false,
                activePanel: 'info'
            })
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.activeProject && nextProps.activeProject !== this.state.activeProject) {
            if (nextProps.activeProject) {
                this.setState({
                    activeProject: nextProps.activeProject,
                    activePanel: 'project'
                })
            } else {
                this.setState({
                    activePanel: 'main'
                })
            }

        }
    }

    componentDidUpdate(prevProps, prevState) {
        // Determine whether to fade out description during panel transition
        if (this.state.activePanel !== prevState.activePanel) {
            const betweenMainAndInfo = (this.state.activePanel === 'info' && prevState.activePanel === 'main') || (this.state.activePanel === 'main' && prevState.activePanel === 'info')
            const windowNotScrolled = (document.body.scrollTop === 0 && document.documentElement.scrollTop === 0)
            if (betweenMainAndInfo && windowNotScrolled) {
                this.setState({
                    fullPanelTransition: false
                })
            } else {
                this.setState({
                    fullPanelTransition: true
                })
            }
        }
    }

    closePanels = () => {
        route('/')
        this.setState({
            activePanel: 'main'
        })
    }

    openInfoPanel = () => {
        this.setState({
            activePanel: 'info',
        })
    }

    onRoute = (e) => {
        if (e.url === '/') {
            this.setState({
                activePanel: 'main',
                projectOpen: false
            })
        } else {
            // Check if project is already visible
            const visibleProject = this.props.content.projects.find(project => project.uid === e.current.attributes.projectID)

            if (visibleProject) {
                this.setState({
                    projectOpen: true,
                    activeProject: visibleProject,
                    activePanel: 'project'
                })
            } else {
                const hiddenProject = this.props.content.allProjects.find(project => project.uid === e.current.attributes.projectID)
                if (hiddenProject) {
                    this.setState({
                        projectOpen: true,
                        activeProject: hiddenProject,
                        activePanel: 'project'
                    })
                    eventBus.emit('showAllProjects')
                } else {
                    route('/')
                }
            }
        }
    }

    onload = (loadedProjects) => {
        this.setState({
            loaded: true,
            loadedProjects: loadedProjects
        })
    }

    scrollToTop = (shouldAnimate) => {
        const scrollingElement = document.documentElement.scrollTop > 0 ?
            document.documentElement : document.body.scrollTop > 0 ?
                document.body : null

        if (!scrollingElement) return

        if (shouldAnimate) {
            TweenLite.to(scrollingElement, 0.3, {
                scrollTop: 0,
                ease: Power1.easeInOut
            })
        } else {
            scrollingElement.scrollTop = 0
        }
    }

	render() {
        const style = css`
            position: relative;
            overflow-x: hidden;
            overflow-y: visible;
            > div {
                transform: translate3d(0,0,0);
            }
        `
        const infoButtonStyle = css`
            > button {
                margin-top: 22px;
                margin-left: 10px;
            }
        `
        const infoXButtonStyle= css`
            position: fixed !important;
            top: 20px;
            right: 20px;
            z-index: 2;
            opacity: ${this.state.activePanel !== 'main' ? 1 : 0};
            transition: ${this.state.activePanel !== 'main' ? `opacity 0.3s 0.7s` : `opacity 0.3s`};
        `
        const infoStyle = css`
            overflow: visible !important;
        `
        const enterSlide = keyframes`
            0% {
                opacity: 0;
                transform: translate3D(0,20px,0);
            }
            100% {
                opacity: 1;
                transform: translate3D(0,0,0);
            }
        `
        const enterFade = keyframes`
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        `
        const exitFade = keyframes`
            0% {
                opacity: 1;
            }
            100% {
                opacity: 0;
            }
        `

        const description = (panel) => {
            const scrollingElement = document.documentElement.scrollTop > 0 ?
                document.documentElement : document.body.scrollTop > 0 ?
                    document.body : null
            // Only fade out and in if page is scrolled down
            const shouldTransition = scrollingElement ? scrollingElement.scrollTop > 0 : false
            const descriptionStyle = css`
                margin: 0 10px 10px 10px;
                padding-top: 40px;
                font-size: 29px;
                line-height: 0.9;
                font-family: MicrogrammaStd-BoldExtended;
                text-transform: uppercase;
                color: black;
                animation: ${this.state.fullPanelTransition ?
                    this.state.activePanel === panel ? `${enterFade} 0.4s both` : `${exitFade} 0.4s both`
                : `none` };
            `
            return (
                <DelayUnmount
                    isMounted={this.state.activePanel === panel}
                    unmountDelay={400}
                    mountDelay={400}>
                    <h2 class={ descriptionStyle }>
                        { this.props.content.info.description }
                    </h2>
                </DelayUnmount>
            )
        }

		const title = this.state.activeProject && this.state.activePanel === 'project' ?
			`${this.props.content.title} - ${this.state.activeProject.title}` :
			this.props.content.title

		return (
			<div class={ style } ref={ div => this.div = div}>
				<Head>
					<title>{title}</title>
					<meta property="og:title" content={title}/>
					<meta name="twitter:title" content={title}/>
					<meta name="og:title" content={title}/>
				</Head>
                <Router onChange={e => this.onRoute(e)}>
                    <div path="/"></div>
                    <div path="/info"></div>
                    <div path="/:projectID?"></div>
                </Router>
                {description('main')}
                {description('info')}
                <TransitionWrapper
                    mount={this.state.activePanel === 'main'}
                    onMount={() => this.scrollToTop(false)}
                >
                    <SquareButton
                        className={ infoButtonStyle }
                        onclick={this.openInfoPanel}
                    >INFO</SquareButton>
                    <Nav
                        path={'/'}
                        projects={this.props.content.projects}
                        content={this.props.content}
                        mediaType={'video'}
                        onload={this.onload}
                        loadedProjects={this.state.loadedProjects}
                        isOpen={this.props.loaderUnmounted}
                        isMobile={true}
                    />
                    <SiteFooter
                        about={this.props.content.info.footerAbout}
                        sections={this.props.content.info.footerSections}
                    />
                </TransitionWrapper>
                <TransitionWrapper
                    mount={this.state.activePanel === 'info'}
                    onMount={() => this.scrollToTop(false)}
                >
                    <Info
                        {...this.props.content.info}
                        class={infoStyle}
                        isOpen={this.state.activePanel === 'info'}
                        isMobile={true}
                    />
                </TransitionWrapper>
                { this.props.content.allProjects.map(project => (
                    <TransitionWrapper
                        mount={this.state.activePanel === 'project' && project === this.state.activeProject}
                        enterStyle={css`
                            animation: ${enterFade} 0.3s forwards;
                        `}
                        enterDuration={0.4}
                        onUnmount={this.scrollToTop}
                        onMount={() => this.scrollToTop(false)}
                    >
                        <ProjectBody
                            {...project}
                            path={'/'+project.uid}
                            content={this.props.content}
                            isOpen={this.state.activePanel === 'project'}
                            sections={ project.sections }
                            browserIsMobile={ true }
                            scrollingParent={document.body}
                        />
                    </TransitionWrapper>
                )) }
                <XButton
                    action="close"
                    color="black"
                    class={infoXButtonStyle}
                    onclick={ this.closePanels }
                />
			</div>
		)
	}
}
