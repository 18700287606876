import { h, Component } from 'preact'
import { css } from 'emotion'
import MuteButton from './MuteButton'
import utils from '../utils'

const slideStyle = css`
    position: relative;
`

const muteButtonStyle = css`
    position: absolute;
    bottom: 40px;
    right: 40px;
    cursor: pointer;
    transform: translate3D(0,0,0);
    @media(max-width: 500px) {
        bottom: 20px;
        right: 20px;
    }
`

export default class Slide extends Component {
    constructor(props) {
        super(props)
        this.state.muted = true
    }

    componentDidMount() {
        if (this.image) {
            if (this.image.complete) this.onImageLoad({target: this.image})
        } else if (this.video) {
            if (this.video.videoWidth !== 0) this.onVideoLoad({target: this.video})
        }
    }

    onImageLoad = (e) => {
        if (this.props.onload) this.props.onload(e)
    }

    onVideoLoad = (e) => {
        this.setState({
            hasAudio: utils.videoHasAudio(this.video),
            loaded: true
        })

        if (this.props.onload) this.props.onload(e)
    }

    onMuteButtonClick = (e) => {
        e.stopPropagation()
        this.setState({
            muted: !this.video.muted
        })
        this.props.onMuteButtonClick(this.state.muted)
    }

    onMuteChange = (e) => {
        this.setState({
            muted: this.video.muted
        })
    }

    render() {
        if (this.props.children.length === 0) return null

        switch (this.props.children[0].nodeName) {
            case 'img':
                return (
                    <div class={this.props.class}>
                        <img
                            {...this.props.children[0].attributes}
                            ref={image => this.image = image}
                            src={this.props.url}
                            alt={this.props.caption}
                            onload={this.onImageLoad}
                        />
                    </div>
                )
                break;

            case 'video':
                return (
                    <div class={`${slideStyle} ${this.props.class || ''}`}>
                        <video
                            {...this.props.children[0].attributes}
                            ref={video => this.video = video}
                            src={this.props.url}
                            alt={this.props.caption}
                            muted={this.state.muted}
                            onloadeddata={this.onVideoLoad}
                            onvolumechange={this.onMuteChange}
                        ></video>
                        {this.state.hasAudio && <MuteButton class={muteButtonStyle} on={!this.state.muted} onclick={this.onMuteButtonClick} />}
                    </div>
                )
                break;
        }
    }
}
