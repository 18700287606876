import { h, Component } from 'preact'
import { css } from 'emotion'

export default class Caption extends Component {
	constructor() {
		super()
	}

	render() {
        const style = css`
            font-size: 20px;
            letter-spacing: 0.05em;
            font-weight: normal;
            line-height: 1.47;
            padding: 0 40px;
            margin: 50px 0 0 0;
            display: flex;
            align-items: center;
            flex-direction: column;
            @media (max-width: 500px) {
                font-size: 16px;
                font-weight: 300;
                padding: 0px 20px;
                margin-top: 20px;
                margin-bottom: 0;
                letter-spacing: 0.05em;
            }
            & div {
                max-width: 850px;
                & a {
                    text-decoration: none;
                    background-image: linear-gradient(to bottom,rgba(0,0,0,1) 50%,rgba(0,0,0,0) 50%);
                    background-repeat: repeat-x;
                    background-size: 2px 2px;
                    background-position: 0 0.94em;
                }
            }
            & p {
                margin: 0;
                text-align: justify;
                @media (max-width: 500px) {
                    margin: 1em 0;
                }
            }
            & p + p::before {
                content: '';
                display: inline-block;
                width: 45px;
            }
        `

		return (
			<section class={ style }>
                <div dangerouslySetInnerHTML={{__html:(this.props.text || '').replace(/<a href=/g, '<a target="_blank" href=')}}>
                </div>
			</section>
		)
	}
}
