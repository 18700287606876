import {h, Component} from 'preact'
import Flickity from 'flickity'

export default class FlickityComponent extends Component {
    constructor(props) {
        super(props)

        this.carousel = null
        this.flkty = null
    }

    create() {
        if (this.carousel) {
            this.flkty = new Flickity(this.carousel, this.props.options)
            this.flkty.select(this.selectedIndex, false, true)
            if (this.props.flickityRef) {
              this.props.flickityRef(this.flkty)
            }
        }
    }

    destroy() {
        if (this.flkty) {
            this.flkty.destroy()
            this.flkty = null
            this.carousel = null
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return true
    }

    componentWillUpdate(nextProps) {
        this.selectedIndex = this.flkty.selectedIndex
        this.destroy()
    }

    componentDidUpdate() {
        this.create()
    }

    componentWillUnmount() {
        this.destroy()
    }

    componentDidMount() {
        this.create()
    }

    render(props) {
        return h(this.props.elementType, {
            className: this.props.className,
            ref: c => {
                this.carousel = c
            }
        }, this.props.children)
    }
}

FlickityComponent.defaultProps = {
    options: {},
    className: '',
    elementType: 'div'
}
