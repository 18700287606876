import { h } from 'preact'
import { css, cx } from 'emotion'

export default ({ children, ...props }) => {
    const tableStyle = css`
        width: 100%;
        flex-grow: 1;
        & .cells {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding-left: 20px;
            padding-right: 30px;
            @media (max-width: 500px) {
                display: block;
                margin-left: 10px;
                padding-left: 0;
            }
        }
        & h3 {
            font-family: MicrogrammaStd-BoldExtended;
            text-transform: uppercase;
            font-size: 11px;
            letter-spacing: 0.1em;
            margin-top: 0;
            margin-bottom: 32px;
        }
        & .cell {
            flex-shrink: 0;
            min-width: 210px;
            @media (max-width: 500px) {
                width: auto;
                margin: 30px 0;
                &:last-child {
                    margin-bottom: 0;
                }
            }
            & h4 {
                text-transform: uppercase;
                font-size: 11px;
                letter-spacing: 0.1em;
                font-weight: normal;
                letter-spacing: 0.15em;
                margin: 0 0 30px 0;
                @media(max-width: 500px) {
                    margin-bottom: 10px;
                }
            }
            & div {
                margin: 0 0 56px 0;
                @media(max-width: 500px) {
                    margin-bottom: 0;
                }
                & a {
                    color: inherit;
                    text-decoration: none;
                }
                & p {
                    margin: 0;
                }
            }
        }
    `

    return (
        <section class={ cx(tableStyle, props.className)  }>
            <h3>{props.title}</h3>
            <div class="cells">
                { props.items.map( cell =>
                    <div class="cell">
                        <h4>{ cell.title }</h4>
                        <div>
                        { cell.lines.map(line => {
                            if (line.url) {
                                return (
                                    <a href={line.url} target="_blank">
                                        <p dangerouslySetInnerHTML={{__html: line.text}}></p>
                                    </a>
                                )
                            } else {
                                return (
                                    <p dangerouslySetInnerHTML={{__html: line.text}}></p>
                                )
                            }
                        }) }
                        </div>
                    </div>
                ) }
            </div>
        </section>
    )
}
