import { h } from 'preact'
import { css } from 'emotion'

import ClickToCopy from '../ClickToCopy'
import SquareButton from '../SquareButton'
import ListSection from '../ListSection'
import TableSection from '../TableSection'

import { eventBus } from '../App'

export default (props) => {
    const SiteFooterStyle = css`
        background: black;
        width: 100%;
        display: flex;
        align-items: flex-start;
        display: flex;
        justify-content: center;
        @media (max-width: 500px) {
            display: block;
        }
        > .center-container {
            max-width: calc(100% - 50px);
            display: flex;
            @media (max-width: 500px) {
                display: block;
                max-width: 100%;
                padding-top: 1px;
                padding-bottom: 1px;
            }
            > div {
                width: 50%;
                margin: 50px 25px;
                @media (max-width: 500px) {
                    width: auto;
                    padding-top: 25px;
                    margin: 15px 20px;
                }
            }
            > div.about {
                -webkit-font-smoothing: antialiased;
                line-height: 1.47;
                letter-spacing: 0.05em;
                text-align: justify;
                & p:first-child {
                    margin-top: 0;
                }
                @media(max-width: 500px) {
                    margin-top: 10px;
                    margin-bottom: 30px;
                    line-height: 1.5;
                }
            }
            > div.misc {
                display: flex;
                align-items: flex-start;
                > div {
                    margin: 0 25px;
                    @media (max-width: 500px) {
                        margin: 20px 0;
                    }
                }
                @media (max-width: 500px) {
                    margin-top: 40px;
                    margin-bottom: 20px;
                }
                > section {
                    word-wrap: break-word;
                }
                > section.ListSection {
                    line-height: 1.81;
                    text-transform: uppercase;
                    letter-spacing: 0.15em;
                    font-size: 11px;
                    width: 31%;
                    margin-left: 15%;
                    > ul {
                        padding: 0;
                        margin: 0;
                        list-style-type: none;
                    }
                    & a {
                        color: white;
                        text-decoration: none;
                    }
                    & li {
                        padding: 0;
                    }
                    > address {
                        font-style: normal;
                    }
                    & a {
                        color: white;
                        text-decoration: none;
                    }
                    @media(max-width: 500px) {
                        width: 50%;
                        margin-left: 0;
                        margin-right: 7%;
                    }
                }
            }
        }
    `

    const infoButtonStyle = css`
        > button {
            color: white;
            border: 1px solid white;
            margin-left: 20px;
            -webkit-font-smoothing: antialiased;
            &:active {
                background: white;
                color: black;
                -webkit-font-smoothing: auto;
            }
        }
    `

    return (
        <div class={ SiteFooterStyle }>
            <div class='center-container'>
                <div class='about' dangerouslySetInnerHTML={{__html: props.about }}>

                </div>
                { window && window.innerWidth <= 500 &&
                    <SquareButton
                        onclick={() => eventBus.emit('openInfoPanel')}
                        className={infoButtonStyle}
                    >READ MORE ABOUT US</SquareButton>
                }
                <div class='misc'>
                    { props.sections.map(block => {
                        switch (block.type) {
                            case 'ListSection':
                                return <ListSection {...block} />
                            case 'TableSection':
                                return <TableSection {...block} />
                        }
                    } ) }
                </div>
            </div>
        </div>
    )
}
