import { h, Component } from 'preact'
import { css } from 'emotion'

const muteButtonStyle = css`
    width: 40px;
    height: 40px;
`

export default function({children, ...props}) {
    if (props.on) {
        return (
            <div class={`${muteButtonStyle} ${props.class}`} onclick={props.onclick || null}>
                <svg width="40px" height="40px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <g id="Desktop" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g fill-rule="nonzero">
                            <circle id="Oval-2" fill-opacity="0.303442029" fill="#000000" cx="20" cy="20" r="20"></circle>
                            <path d="M14,17 L20,12 L20,28 L14,23 L10,23 L10,17 L14,17 Z" id="Combined-Shape" fill="#FFFFFF"></path>
                            <path d="M24,25 C25.13935,24.2909305 26,22.1461813 26,20 C26,17.8560421 25.1410301,15.7106549 24.0035399,15" id="Oval-3" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round"></path>
                            <path d="M27,27 C28.709025,26.0073027 30,23.0046538 30,20 C30,16.9984589 28.7115452,13.9949169 27.0053099,13" id="Oval-3-Copy" stroke="#FFFFFF" stroke-width="1.5" stroke-linecap="round"></path>
                        </g>
                    </g>
                </svg>
            </div>
        )
    } else {
        return (
            <div class={`${muteButtonStyle} ${props.class}`} onclick={props.onclick || null}>
                <svg width="40px" height="40px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <g fill-rule="evenodd">
                        <circle fill-opacity="0.303442029" fill="#000000" fill-rule="nonzero" cx="20" cy="20" r="20"></circle>
                        <g stroke-width="1" fill-rule="evenodd" transform="translate(26.863961, 20.500000) rotate(45.000000) translate(-26.863961, -20.500000) translate(22.363961, 16.000000)" stroke="#FFFFFF" stroke-linecap="round">
                            <path d="M9,4.5 L0,4.5" id="Line-2" stroke-width="1.5" fill-rule="nonzero"></path>
                            <path d="M9,4.5 L0,4.5" id="Line-2" stroke-width="1.5" fill-rule="nonzero" transform="translate(4.500000, 4.500000) rotate(90.000000) translate(-4.500000, -4.500000) "></path>
                        </g>
                        <path d="M14,17 L20,12 L20,28 L14,23 L10,23 L10,17 L14,17 Z" fill="#FFFFFF" fill-rule="nonzero"></path>
                    </g>
                </svg>
            </div>
        )
    }
}
