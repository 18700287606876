import { h, Component } from 'preact'
import { css } from 'emotion'
import utils from './utils'

import InfoButton from './InfoButton'
import PhoneWrapper from './PhoneWrapper'

class Background extends Component {
    onMouseMove = (e) => {
        const x = utils.remap(e.pageX, 0, this.background.offsetWidth, 1, -1)
        const y = utils.remap(e.pageY, 0, this.background.offsetHeight, 1, -1)
        this.background.style.transform = `scale(1.05) translate3d(${x}%, ${y}%, 0)`
    }

    componentDidMount() {
        const image = new Image()
        image.onload = () => {
            if (this.props.onload) this.props.onload()
        }
        image.src = this.props.backgroundImage
        window.addEventListener('mousemove', this.onMouseMove)
    }

    componentWillUnmount() {
        window.removeEventListener('mousemove', this.onMouseMove)
    }

    render() {
        const backgroundStyle = css`
            width: 100%;
            height: 100%;
            background-image: url(${this.props.backgroundImage});
            background-size: cover;
            background-position: center;
            position: absolute;
            top: 0;
            left: 0;
            transform: scale(1.05) translate3d(0,0,0);
            transition: transform 0.3s ease-out;
        `

        return (
            <div class={ backgroundStyle } ref={ background => this.background = background }></div>
        )
    }
}

export default class ProjectHeader extends Component {
	constructor(props) {
		super(props)
        this.state.heroHovered = false
	}

    componentWillReceiveProps(nextProps) {
        if (nextProps.panelIsActive && nextProps.isActive) {
            if (this.player) this.player.play()
        } else {
            if (this.player) this.player.pause()
        }
    }

    onHeroMouseOver() {
        this.setState({
            heroHovered: true
        })
    }

    onHeroMouseOut() {
        this.setState({
            heroHovered: false
        })
    }

    onHeroLoad = () => {
        this.setState({
            heroLoaded: true
        })
        if (this.state.backgroundLoaded && this.props.onload) this.props.onload()
    }

    onBackgroundLoad = () => {
        this.setState({
            backgroundLoaded: true
        })
        if (this.state.heroLoaded && this.props.onload) this.props.onload()
    }

	render() {
        const projectHeaderStyle = css`
            position: relative;
            top: 0;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
        `

        const labelStyle = css`
            position: absolute;
            top: 32px;
            right: 40px;
            z-index: -1;
            opacity: ${!this.props.isOpen && this.state.heroHovered ? 1 : 0 };
            transition: opacity 0.3s;
            text-align: right;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            z-index: 1;
            text-shadow: ${this.props.uiColor === 'white' ? '0px 0px 3px rgba(0,0,0,0.2)' : 'none'};
            pointer-events: none;
            color: ${this.props.uiColor};
            > h2 {
                font-family: MicrogrammaStd-BoldExtended;
                text-transform: uppercase;
                font-size: 38px;
                margin: 0;
                letter-spacing: 0.05em;
                -webkit-font-smoothing: antialiased;
                @media(max-width: 1400px) {
                    font-size: 32px;
                }
            }
            > p {
                margin: 7px 0;
                font-size: 30px;
                line-height: 1.25em;
                letter-spacing: 0.04em;
                width: 600px;
                @media(max-width: 1400px) {
                    width: 500px;
                    font-size: 25px;
                }
            }
        `

        const heroStyle = css`
            position: relative;
            transition: transform 0.3s;
            transform: ${ this.props.isOpen ? 'translateY(-40px) scale(1.01)' : 'scale(1)' };
            height: ${ this.props.isMobile ? '100%' : 'auto'};
            max-width: 80vw;
            max-height: calc(80 * var(--vh));
            box-shadow: ${ this.props.isMobile ? null : '0px 2px 11px rgba(0,0,0,0.21)' };
            &:hover {
                transform: scale(1.01);
            }
            > video {
                display: block;
                max-width: 80vw;
                max-height: calc(80 * var(--vh));
            }
        `

        const phoneWrapperStyle = css`
            height: 100%;
        `

		return (
            <header class={ projectHeaderStyle+' '+(this.props.class || '') } >
                <Background backgroundImage={ this.props.backgroundImage } onload={this.onBackgroundLoad}/>

                <div class={ labelStyle }>
                    <h2>{this.props.title}</h2>
                    <p>{this.props.description}</p>
                </div>

                <a
                    class={ heroStyle }
                    href={ `/${this.props.uid}` }
                    onmouseover={ () => this.onHeroMouseOver() }
                    onmouseout={ () => this.onHeroMouseOut() }
                >
                    { this.props.isMobile ? (
                        <PhoneWrapper class={phoneWrapperStyle} onload={this.onHeroLoad} playVideo={this.props.panelIsActive && this.props.isActive}>
                            <video
                                ref={ player => this.player = player }
                                src={ this.props.heroVideo }
                                poster={ this.props.heroVideoPoster }
                                onloaddeddata={this.onHeroLoad}
                                playsinline
                                autoPlay={this.props.panelIsActive && this.props.isActive}
                                preload="metadata"
                                muted
                                loop
                            />
                        </PhoneWrapper>
                    ) : (
                        <video
                            ref={ player => this.player = player }
                            src={ this.props.heroVideo }
                            poster={ this.props.heroVideoPoster }
                            onloaddeddata={this.onHeroLoad}
                            playsinline
                            autoplay
                            muted
                            loop
                        />
                    ) }
                </a>
            </header>
		)
	}
}
