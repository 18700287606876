import { h, Component } from 'preact'
import { css, keyframes } from 'emotion'
import utils from './utils'
import { throttle } from 'underscore'

import ProjectHeader from './ProjectHeader'
import ProjectBody from './ProjectBody'
import DelayUnmount from './DelayUnmount'

export default class Project extends Component {
    onBodyScroll = (e) => {
        const hideTitle = (e.target.scrollTop - window.innerHeight - 80) >= -210
        if (this.state.hideTitle !== hideTitle) {
            this.setState({
                hideTitle: (e.target.scrollTop - window.innerHeight - 80) >= -210
            })
            this.props.onscroll( hideTitle ? 'black' : this.props.project.uiColor )
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.isOpen === false && nextProps.isOpen === true ) {
            this.setState({
                hideTitle: false
            })
        }
    }

	render() {
        const projectStyle = css`
            position: relative;
            width: 100%;
            height: 100vh;
        `

        const headerStyle = css`
            transition: height 0.3s;
            height: 100%;
        `

        const slideOutDurationMS = 700

        const slideIn = keyframes`
            from {
                transform: translateY(80px);
            }
            to {
                transform: translateY(0);
            }
        `

        const slideOut = keyframes`
            from {
                transform: translateY(0);
            }
            to {
                transform: translateY(100vh);
            }
        `

        const bodyStyle = css`
            height: 100vh;
            position: absolute;
            top: 0;
            padding-top: calc(100vh - 80px);
            overflow-y: ${ this.props.isOpen ? 'scroll' : 'hidden' };
            animation: ${ this.props.isOpen ? `${ slideIn } 0.3s both` : `${ slideOut } ${ slideOutDurationMS/1000 }s both ease-in` };
        `

		return (
			<div class={ projectStyle }>
                <ProjectHeader
                    { ...this.props.project }
                    class={ headerStyle }
                    isOpen={ this.props.isOpen }
                    isActive={ this.props.isActive }
                    panelIsActive={ this.props.panelIsActive }
                    onload={this.props.onload}
                />
                <DelayUnmount
                    unmountDelay={ slideOutDurationMS + 100 }
                    isMounted={ this.props.isActive && this.props.isOpen }
                >
                    <ProjectBody
                        { ...this.props.project }
                        content={this.props.content}
                        class={ bodyStyle }
                        onscroll={ this.onBodyScroll }
                        hideTitle={ this.state.hideTitle }
                        isOpen={ this.props.isActive && this.props.isOpen }
                        section={ this.props.project.sections }
                    />
                </DelayUnmount>
			</div>
		)
	}
}
