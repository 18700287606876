import { h, Component } from 'preact'
import { css } from 'emotion'
import mitt from 'mitt'
import utils from './utils'

import MobileLayout from './MobileLayout'
import DesktopLayout from './DesktopLayout'
import DelayUnmount from './DelayUnmount'
import Loader from './Loader'

// Global event bus
export const eventBus = mitt()

export default class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            content: {
                projects: [],
                info: {},
                url: window.SITE.url
            },
            loaded: false,
            loaderUnmounted: false,
            showAllProjects: false
        }
    }

    cacheInnerHeight = () => {
        // Save 1% the innerHeight to get the initial innerHeight on mobile browsers
        const vh = window.innerHeight * 0.01
        document.documentElement.style.setProperty('--vh', `${vh}px`)
    }

    componentDidMount() {
        // Make :active state work on safari
        document.addEventListener("touchstart", function() {}, false)

        // Try to autoplay videos when window returns to focus
        window.addEventListener('focus', () => {
            document.querySelectorAll('video[autoplay]', e => {
                try {
                    e.target.play()
                } catch (e) {
                    // Fail silently
                }
            })
        }, false)

        eventBus.on('showAllProjects', () => {
            this.setState({
                content: {
                    ...this.state.content,
                    projects: this.state.content.allProjects
                },
                showAllProjects: true
            })
        })

        this.setState({
            isMobile: window.innerWidth < 500
        })

        this.fetchAllProjects()

        this.cacheInnerHeight()
        window.addEventListener('resize', this.cacheInnerHeight)
    }

    fetchAllProjects = async () => {
        var request = new XMLHttpRequest()
        request.addEventListener("load", (e) => {
            try {
                const content = JSON.parse(e.target.responseText)
                //Mock info data
                this.setState({
                    content: {
                        ...content,
                        allProjects: content.projects,
                        projects: content.projects.slice(0,5)
                    },
                    loaded: true
                })
            } catch (e) {
                console.error('Error parsing projects JSON from server', e)
            }
        })
        request.open("GET", this.props.site.url+"/api/v1/content")
        request.send()
    }

    onLoaderUnmounted = () => {
        this.setState({
            loaderUnmounted: true
        })
    }

    render() {
        const style = css`
            width: 100%;
            height: 100vh;
            @media (max-width: 500px) {
                height: auto;
            }
        `
        if (this.state.loaded && this.state.isMobile !== undefined) {
            var layout = this.state.isMobile ?
                <MobileLayout content={this.state.content} loaderUnmounted={this.state.loaderUnmounted} /> : <DesktopLayout content={this.state.content} showAllProjects={this.state.showAllProjects}/>
        } else {
            var layout = null
        }

        return(
            <div class={style}>
                <Loader siteLoaded={this.state.loaded} onUnmount={this.onLoaderUnmounted}/>
                {layout}
            </div>
        )
    }
}
