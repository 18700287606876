import {h, Component} from 'preact'
import CarouselSlide from './CarouselSlide'
import {css} from 'emotion'

export default class StaticCarousel extends Component {
    componentDidMount() {
        this.observer = new IntersectionObserver(intersections => this.onVisibilityChange(intersections))
        this.observer.observe(this.section)
    }

    onVisibilityChange(intersections) {
        const ratio = intersections[0].intersectionRatio
        const videos = this.section.querySelectorAll('video')
        videos.forEach(video => {
            if (ratio > 0) {
                video.play()
            } else {
                video.pause()
            }
        })
    }

    componentWillUnmount() {
        this.observer.disconnect()
    }

    // When one video is unmuted, mute the others in this row
    onMuteButtonClick = (muted, clickedIndex) => {
        if (muted === false) {
            this.section.querySelectorAll('video').forEach( (video, index) => {
                if (index !== clickedIndex) video.muted = true
            })
        }
    }

    render() {
        const staticCarousel = css`
            display: flex;
            width: 100%;
            justify-content: center;
            margin-top: 120px;
            padding: 0 100px;
            @media(max-width: 500px) {
                margin-top: 40px;
            }
            > .center-container {
                max-width: 1400px;
                > .slides {
                    display: flex;
                    > .slide {
                        margin: 0 20px;
                        width: 100%;
                        height: auto;
                        flex-shrink: 1;
                        > video {
                            width: 100%;
                            max-height: 750px;
                        }
                    }
                    > .slide:first-child {
                        margin-left: 0;
                    }
                    > .slide:last-child {
                        margin-right: 0;
                    }
                }
                > footer {
                    margin: 20px 0px 0px;
                    min-height: 17px;
                    font-size: 11px;
                    text-align: right;
                    @media (max-width: 500px) {
                        margin: 20px;
                    }
                    > .caption {
                        text-transform: uppercase;
                        letter-spacing: 0.15em;
                        padding-top: 4px;
                    }
                    > .caption.mobile {
                        text-align: center;
                        margin-top: 0;
                        margin-bottom: 20px;
                        hyphens: auto;
                    }
                }
            }
        `

        const isMobile = window.matchMedia('(max-width: 500px)').matches

        return (
            <section class={staticCarousel} ref={section => this.section = section}>
                <div class="center-container">
                    {isMobile && (
                        <footer>
                            <div class="caption mobile" dangerouslySetInnerHTML={{__html:(this.props.caption || '').replace(/<a href=/g, '<a target="_blank" href=')}}>
                            </div>
                        </footer>
                    )}
                    <div class="slides">
                        {this.props.slides.map( (slide, index) => (
                            <CarouselSlide {...slide} class={'slide'} onload={null} muted={this.state.muted} onMuteButtonClick={this.onMuteButtonClick}>
                            { slide.type === 'image' ?
                                <img
                                    src={slide.url}
                                    alt={slide.caption}
                                />
                            :
                                <video
                                    alt={slide.caption}
                                    src={slide.url}
                                    preload="auto"
                                    playsinline
                                    loop
                                    autoplay
                                ></video>
                            }
                            </CarouselSlide>
                        ))}
                    </div>
                    {!isMobile && (
                        <footer>
                            <div class="caption" dangerouslySetInnerHTML={{__html:(this.props.caption || '').replace(/<a href=/g, '<a target="_blank" href=')}}>
                            </div>
                        </footer>
                    )}
                </div>
            </section>
        )
    }
}
