import { h } from 'preact'
import { css } from 'emotion'

import ListSection from '../ListSection'
import TableSection from '../TableSection'

export default ({ children, ...props }) => {
    const projectFooterStyle = css`
        display: flex;
        background: white;
        color: black;
        margin-top: 120px;
        border-top: 1px solid rgba(0,0,0,0.2);
        @media (max-width: 500px) {
            display: block;
            margin-top: 40px;
        }
    `

    const sectionStyle = css`
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 100px 30px 100px 70px;
        flex: 1 1 auto;
        @media (max-width: 500px) {
            flex-direction: column;
            padding: 50px 20px;
            width: 100%;
        }
        &:nth-child(even) {
            border-left: 1px solid rgba(0,0,0,0.2);
            @media (max-width: 500px) {
                border-left: none;
            }
        }
        @media (max-width: 500px) {
            border-top: 1px solid rgba(0,0,0,0.2);
            &:first-child {
                border-top: 0;
            }
        }
        & section {
            /* width: 400px; */
            max-width: 1400px;
        }
    `

    return (
        <footer class={ projectFooterStyle }>
            {[...props.sections].map(section => {
                switch (section.type) {
                    case 'ListSection':
                        return (
                            <div class={ sectionStyle }>
                                <ListSection {...section} />
                            </div>
                        )
                    case 'TableSection':
                        return (
                            <div class={ sectionStyle }>
                                <TableSection {...section} />
                            </div>
                        )
                    default:
                }
            })}
        </footer>
    )
}
