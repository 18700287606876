import { h, Component } from 'preact'
import { css, keyframes } from 'emotion'

import XButton from './XButton'
import ClickToCopy from './ClickToCopy'
import ListSection from './ListSection'
import {ListSectionStyle} from './ListSection'

export default class Info extends Component {
    constructor() {
        super()
    }

    componentWillReceiveProps(nextProps) {
        if (!this.props.isOpen && nextProps.isOpen && this.div) this.div.scrollTop = 0
    }

    render() {
        const slideIn = keyframes`
            0% {
                opacity: 0;
                transform: translateY(50px);
            }
            100% {
                opacity: 1;
                transform: translateY(0);
            }
        `

        const style = css`
            color: black;
            padding: 37px 40px 20px 40px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            overflow-y: visible;
            overflow-x: hidden;
            opacity: ${ this.props.isOpen ? 1 : 0 };
            transition: ${ this.props.isOpen ? 'opacity 0.4s 0.7s' : 'opacity 0.4s' };
            @media (max-width: 500px) {
                padding: 10px;
                opacity: 1;
                transition: none;
            }
            & h2 {
                margin-top: 0;
                margin-bottom: 18px;
                font-size: 38px;
                line-height: 0.85;
                font-family: MicrogrammaStd-BoldExtended;
                text-transform: uppercase;
                @media (max-width: 500px) {
                    display: none;
                }
            }
            & h4 {
                text-transform: uppercase;
                font-size: 11px;
                font-weight: normal;
                letter-spacing: 0.15em;
                > a {
                    color: inherit;
                    text-decoration: none;
                }
            }
            & h3 {
                font-family: MicrogrammaStd-BoldExtended;
                text-transform: uppercase;
                font-size: 11px;
                letter-spacing: 0.1em;
                margin-top: 0;
                margin-bottom: 18px;
                @media(max-width: 500px) {
                    margin-bottom: 30px;
                }
            }
            & p {
                font-size: 11px;
                letter-spacing: 0.1em;
                margin: 0;
                font-style: normal;
            }
            & .clients {
                & p {
                    font-size: 30px;
                    line-height: 1.3;
                    font-weight: 300;
                    text-align: justify;
                    letter-spacing: 0.01em;
                    @media (max-width: 500px) {
                        font-size: 27px;
                        padding-left: 10px;
                        padding-right: 10px;
                    }
                }
            }
            & .about {
                & p {
                    font-size: 30px;
                    line-height: 1.3;
                    font-weight: 300;
                    text-align: justify;
                    letter-spacing: 0.01em;
                    @media (max-width: 500px) {
                        font-size: 18px;
                        letter-spacing: 0.025em;
                        padding: 0 10px;
                        text-align: left;
                        letter-spacing: 0.05em;
                        margin: 1em 0em;
                        line-height: 1.4;
                    }
                }
            }
            & .about p::before {
                content: '';
                display: inline-block;
                width: 28px;
                @media (max-width: 500px) {
                    display: none;
                }
            }
            & section {
                width: 100%;
                margin-bottom: 50px;
                & ul {

                }
            }
            & section.ListSection {
                @media(max-width: 500px) {
                    & ul {
                        padding: 0 10px;
                    }
                }
            }
        `

        return (
            <div href="#" class={`${style} ${this.props.class}`} ref={ div => this.div = div }>
                <section>
                    <h2>{ this.props.description }</h2>
                    <h4 dangerouslySetInnerHTML={{__html: this.props.managementText}}> </h4>
                </section>
                <section class="clients">
                    <h3>Clients Include</h3>
                    <p dangerouslySetInnerHTML={{__html: this.props.clients}}></p>
                </section>
                <section class="about">
                    <h3>About</h3>
                    <div dangerouslySetInnerHTML={{__html: this.props.about}}></div>
                </section>
                {this.props.sections.map(section => {
                    if (this.props.isMobile) {
                        var width = section.mobileWidth || '100%'
                    } else {
                        var width = section.desktopWidth || '50%'
                    }

                    const sectionStyle = css`
                        width: ${width};
                        word-break: ${width === '50%' ? 'break-all' : 'normal'};
                    `

                    switch (section.type) {
                        case 'ListSection':
                            return (
                                <div class={ sectionStyle }>
                                    <ListSection {...section} />
                                </div>
                            )
                        case 'TableSection':
                            return (
                                <div class={ sectionStyle }>
                                    <TableSection {...section} />
                                </div>
                            )
                        default:
                    }
                })}
            </div>
        )
    }
}
