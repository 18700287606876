import { h, Component } from 'preact'

export default class DelayUnmount extends Component {
   constructor(props) {
        super(props)
        this.state = {
            shouldRender: this.props.isMounted
        }
   }

   componentWillReceiveProps(nextProps) {
        if (this.props.isMounted && !nextProps.isMounted) {
            clearTimeout(this.timer)
            if (this.props.unmountDelay) {
                this.timer = setTimeout(() => {
                    this.setState({
                        shouldRender: false
                    })
                }, this.props.unmountDelay)
            } else {
                this.setState({ shouldRender: true })
            }
        } else if (!this.props.isMounted && nextProps.isMounted) {
            clearTimeout(this.timer)
            if (this.props.mountDelay) {
                this.timer = setTimeout(() => {
                    this.setState({
                        shouldRender: true
                    })
                }, this.props.mountDelay)
            } else {
                this.setState({ shouldRender: true })
            }
        }
   }

    render() {
        return this.state.shouldRender ? (
            <div>
                { this.props.children }
            </div>
        ) : null
    }
}
