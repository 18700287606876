import { h, Component } from 'preact'
import { css, keyframes } from 'emotion'
import clipboard from "clipboard-polyfill/build/clipboard-polyfill.promise"

var messageBlink = keyframes`
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
`

export default class ClickToCopy extends Component {
    componentDidMount() {
        this.setState({
            color: getComputedStyle(this.span).color
        })
    }

    onMouseLeave() {
        this.setState({
            copied: false
        })
    }

    copy() {
        clipboard.writeText(this.props.value)
        this.setState({
            copied: true
        })
    }

    render() {
        const clickToCopyStyle = css`
            position: relative;
            display: block;
            cursor: pointer;
            & .contents {
                opacity: 1;
                transition: opacity 0.1s;
            }
            & .message {
                display: flex;
                align-items: center;
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                visibility: hidden
            }
            &:hover .contents {
                opacity: 0;
            }
            &:hover .message {
                visibility: visible;
                animation: ${!this.state.copied ? `${ messageBlink } 0.6s infinite` : ''};
            }
            & svg {
                margin-right: 2px;
            }
        `

        if (this.state.copied) {
            var message = this.props.copiedMessage || 'Copied to clipboard'
        } else {
            var message = this.props.copyMessage || 'Click to copy'
        }

        return (
            <span
                class={ clickToCopyStyle }
                onclick={ e => this.copy() }
                ref={span => this.span = span}
            >
                <div class="contents">
                    { this.props.children[0] }
                </div>
                <div class="message">
                    <span>
                        { this.state.copied &&
                            <svg width="12" height="10" viewBox="0 0 9 8" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.9 6.465L7.72.513l.776.63L2.944 8l-.001-.002-.003.005L.096 4.845l.743-.67L2.9 6.465z" fill={this.state.color} fill-rule="nonzero"/>
                            </svg>
                        }
                        { message }
                    </span>
                </div>
            </span>
        )
    }
}
