import {h, Component} from 'preact'
import {css, keyframes} from 'emotion'

import DelayUnmount from './DelayUnmount'

export default class Loader extends Component {
    constructor(props) {
        super(props)
        this.state.timerComplete = false
    }

    loaderReady = () => {
        // Once loader image has loaded,
        // the loader should last at least 1000ms
        this.minimumDurationTimer = setTimeout(() => {
            this.setState({
                timerComplete: true
            })
        }, 1000)
    }

    render() {
        const mountLoader = this.state.timerComplete === false || this.props.siteLoaded === false
        return (
            <DelayUnmount isMounted={mountLoader} unmountDelay={700}>
                <LoaderInner isMounted={mountLoader} onload={this.loaderReady} onUnmount={this.props.onUnmount}/>
            </DelayUnmount>
        )
    }
}

class LoaderInner extends Component {
    componentDidMount() {
        const image = new Image()
        image.onload = () => {
            this.setState({
                imageLoaded: true
            })
            this.props.onload && this.props.onload()
        }
        image.src = './assets/images/DVTK_Loader_spreadsheet.png'
    }

    componentWillUnmount() {
        this.props.onUnmount && this.props.onUnmount()
    }

    render() {
        const loaderAnimation = keyframes`
            from {
                background-position: 0px 0px;
            }
            to {
                background-position: 0px -1080px;
            }
        `
        const loaderStyle = css`
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: white;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 10;
            transition: opacity 0.7s 0.4s;
            opacity: ${this.props.isMounted ? 1 : 0};
            > div {
                width: 120px;
                height: 120px;
                background-image: url(./assets/images/DVTK_Loader_spreadsheet.png) !important;
                background-position: 0% 0%;
                animation: ${loaderAnimation} .4s steps(9) infinite;
                opacity: ${this.state.imageLoaded && this.props.isMounted ? 1 : 0};
                transform: ${this.state.imageLoaded && this.props.isMounted ? 'scale(1)' : 'scale(0.9)'};
                transition: opacity 0.3s, transform 0.3s;
            }
        `

        return (
            <div class={loaderStyle}>
                <div>
                </div>
            </div>
        )
    }
}
