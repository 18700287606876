import { h, Component } from 'preact'
import { css } from 'emotion'

export default class TextBlock extends Component {
	constructor() {
		super()
	}

	render() {
        const style = css`
            font-size: 30px;
            font-weight: normal;
            line-height: 1.5;
            padding: 0 120px;
            margin: 120px 0 0 0;
            @media (max-width: 500px) {
                margin-top: 40px;
                padding: 0 20px;
            }
            > p {
                margin: 0;
                margin-bottom: 1.5rem;
                letter-spacing: 0;
                font-weight: 300;
                text-align: justify;
                & a {
                    text-decoration: none;
                    background-image: linear-gradient(to bottom,rgba(0,0,0,1) 50%,rgba(0,0,0,0) 50%);
                    background-repeat: repeat-x;
                    background-size: 2px 3px;
                    background-position: 0 0.94em;
                }
                @media (max-width: 500px) {
                    letter-spacing: 0.05em;
                    font-weight: normal;
                    font-size: 18px;
                    line-height: 1.45;
                    font-weight: 300;
                    margin-bottom: 1.3rem;
                    text-align: left;
					hyphens: auto;
                }
            }
            > p:last-child {
                margin-bottom: 0;
            }
        `
		return (
			<section class={ style } dangerouslySetInnerHTML={{__html: (this.props.text || '').replace(/<a href=/g, '<a target="_blank" href=')}}>

			</section>
		)
	}
}
