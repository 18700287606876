import { h, Component } from 'preact'
import { css } from 'emotion'

// Wraps a single image or video in an iPhone X frame
// Media should be 550px x 1194px
// props = {
//     class: CSS class name,
//     children
// }
export default class PhoneWrapper extends Component {

    componentDidUpdate(prevProps, prevState) {
        if (this.childVideo && prevProps.playVideo !== this.props.playVideo) {
            this.props.playVideo ? this.childVideo.play() : this.childVideo.pause()
        }
    }

    componentDidMount() {
        if (this.childVideo && this.childVideo.videoWidth) {
            this.onChildLoad()
        } else if (this.childImage && this.childImage.naturalWidth !== 0) {
            this.onChildLoad()
        }
        if (this.phone && this.phone.naturalWidth !== 0) {
            this.onPhoneLoad()
        }
    }

    onPhoneLoad = () => {
        this.setState({
            phoneLoaded: true
        }, () => {
            this.onload()
        })
    }

    onChildLoad = () => {
        this.setState({
            childLoaded: true
        }, () => {
            this.onload()
        })
    }

    // this.props.onload should only be called when
    // child (either <video> or <img>) is loaded enough to display something
    // the phone frame image is loaded
    onload = () => {
        if (this.state.phoneLoaded && this.state.childLoaded && this.props.onload) {
            this.props.onload()
        }
    }

    render() {
        const phoneWrapperStyle = css`
            position: relative;
            > div {
                width: 95%;
                height: 95%;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                margin: auto;
                background: black;
                border-radius: 5%;
                transition: opacity 0.3s;
                opacity: ${this.state.phoneLoaded ? 1 : 0};
                & > img, video {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    width: 92%;
                    display: block;
                    transition: opacity 0.3s;
                    opacity: ${(this.state.childLoaded && this.state.phoneLoaded) ? 1 : 0};
                }
            }
        `

        const phoneFrameStyle = css`
            position: relative;
            height: 100%;
            z-index: 1;
            transform: translate3d(0,0,0);
            opacity: ${this.state.phoneLoaded ? 1 : 0};
            transition: opacity 0.3s;
        `

        return (
            <div
                class={`${phoneWrapperStyle} ${this.props.class || ''}` }
            >
                <div>
                    { this.props.children[0].nodeName === 'img' &&
                        <img
                            {...this.props.children[0].attributes}
                            ref={childImage => this.childImage = childImage}
                            onload={this.onChildLoad}
                            src={this.props.children[0].attributes.src}
                        />
                    }
                    { this.props.children[0].nodeName === 'video' &&
                        <video
                            {...this.props.children[0].attributes}
                            ref={childVideo => this.childVideo = childVideo}
                            onloadeddata={this.onChildLoad}
                        ></video>
                    }
                </div>
                <img
                    ref={phone => this.phone = phone}
                    class={ phoneFrameStyle }
                    onload={ this.onPhoneLoad }
                    src={ window.SITE.url+'/assets/images/Apple iPhone X Silver.png' }
                />
            </div>
        )
    }
}
