import { h } from 'preact'
import { css } from 'emotion'

import SquareButton from '../SquareButton'

export default ({ CTA, onClickScrollToTop }) => {
    const CTAStyle = css`
        display: flex;
        justify-content: center;
        margin-top: 120px;
        @media (max-width: 500px) {
            display: block;
            text-decoration: none;
            margin-top: 40px;
            & button {
                display: block;
                margin-bottom: 10px;
                margin-left: 20px;
            }
        }
    `

    return (
        <div class={ CTAStyle } >
            { CTA.url && CTA.text &&
                <SquareButton href={ CTA.url } target="_blank">
                    { CTA.text.toUpperCase() }
                </SquareButton>
            }
            <SquareButton href={ '/' }>
                CLOSE PROJECT
            </SquareButton>
            <SquareButton onclick={ e => onClickScrollToTop(e) }>
                BACK TO TOP
            </SquareButton>
        </div>
    )
}
