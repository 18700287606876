export default {
    remap: function remap(x, in_min, in_max, out_min, out_max) {
        if (x < in_min) {
            x = in_min
        }
        if (x > in_max) {
            x = in_max
        }
        return (x - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
    },
    clip: function clip(x, min, max) {
        if (x < min) x = min
        if (x > max) x = max
        return x
    },
    // Shuffle an array
    shuffleArray: arr => (
      arr
        .map(a => [Math.random(), a])
        .sort((a, b) => a[0] - b[0])
        .map(a => a[1])
    ),
    // Repeat an array a certain number of times. Returns new array.
    repeatArray: (array, amount) => {
        var result = []
        for (var i = 0; i < amount; i++) {
            result = result.concat(array)
        }

        return result
    },
    compareProps: (props, nextProps) => {
        for (const key in nextProps) {
            if (nextProps[key] !== props[key]) {
              console.log(key, props[key], '-->', nextProps[key]);
            }
        }
    },
    videoHasAudio: (video) => {
        return video.mozHasAudio ||
        Boolean(video.webkitAudioDecodedByteCount) ||
        Boolean(video.audioTracks && video.audioTracks.length);
    }
}
