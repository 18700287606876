import { h, Component } from 'preact'
import { css } from 'emotion'
import Flickity from '../FlickityComponent'
import CarouselSlide from './CarouselSlide'
import utils from '../utils'

import innerHeight from 'ios-inner-height'

export default class Carousel extends Component {
    constructor(props) {
        super(props)
        this.state.muted = true
        this.state.slides = this.props.slides
    }

    componentDidMount() {
        this.observer = new IntersectionObserver(intersections => this.onVisibilityChange(intersections))
        this.observer.observe(this.section)
        if (window.matchMedia('(max-width: 500px)').matches && this.flickity) this.adjustSizeOnMobile()
    }

    onDragStart = e => {
        this.dragging = true
        this.dragStartX = (e.clientX || e.pageX)
        document.ontouchmove = (e) => {
            if (this.dragging && Math.abs(this.dragStartX - (e.clientX || e.pageX)) > 10) {
                e.preventDefault()
            }
        }
    }

    onDragEnd = e => {
        this.dragging = false
        document.ontouchmove = (e) => {
            return true
        }
    }

    onVisibilityChange(intersections) {
        if (!this.flickity.selectedElement) return
        const ratio = intersections[0].intersectionRatio
        const selectedElement = this.flickity.selectedElement.querySelector('video')

        if (selectedElement) {
            if (ratio > 0) {
                selectedElement.play()
            } else {
                if (!selectedElement.paused) selectedElement.pause()
            }
        }
    }

    onMouseMove(e) {
        if (this.flickity) {
            this.flickity.viewport.style.cursor = e.pageX < window.innerWidth / 2 ? 'w-resize' : 'e-resize'
        }
    }

    onClick(e) {
        // Ignore clicks if we are clicking the numbers
        if (e.target.classList.contains('dot')) return
        if (window.innerWidth < 500) return
        if (e.pageX < window.innerWidth / 2) {
            this.flickity.previous()
        } else {
            this.flickity.next()
        }
    }

    onNumberClick(index) {
        this.flickity.select(index)
    }

    onSlideSelect = (e) => {
        if (!this.flickity) return
        this.flickity.cells.forEach(async (cell, index) => {
            const video = cell.element.querySelector('video')
            if (video) {
                if (index === this.flickity.selectedIndex) {
                    try {
                        await video.play()
                    } catch(err) {

                    }
                } else {
                    if (!video.paused) video.pause()
                }
            }
        })
    }

    onMuteButtonClick = (muted) => {
        // Circumvent react render cycle and mute/unmute <video> elements directly.
        // We have to do this because if we render the Carousel's children more than once,
        // the Flickity instance will be re-instantiated and we get ugly side-effects.
        // Each video's mute button listens to the actual <video> element as its
        // source of truth, so the button state will update that way instead of receiving props.
        // It's ugly but it works.
        this.section.querySelectorAll('video').forEach( video => video.muted = muted)
    }

    // Periodically check if video is ACTUALLY loaded
    checkIfVideoIsLoaded(e, loadedSlide) {
        // Check if slide has actually loaded (i.e. there is a videoWidth)
        if (e.target.videoWidth === 0) {
            setTimeout(() => this.checkIfVideoIsLoaded(e, loadedSlide), 400)
        } else {
            this.setState({
                slides: this.state.slides.map(slide => {
                    if (slide === loadedSlide) {
                        return {
                            ...slide,
                            loaded: true
                        }
                    } else {
                        return slide
                    }
                })
            })
        }
    }

    onSlideLoad = (e, loadedSlide) => {
        this.checkIfVideoIsLoaded(e, loadedSlide)
        if (window.matchMedia('(max-width: 500px)').matches && this.flickity) this.adjustSizeOnMobile()
    }

    componentWillUnmount() {
        this.observer.disconnect()
        this.flickity.off('dragStart', this.onDragStart)
        this.flickity.off('dragEnd', this.onDragEnd)
        this.flickity.destroy()
    }

    shouldComponentUpdate(nextProps, nextState) {
        return this.state !== nextState
    }

    adjustSizeOnMobile() {
        const getMediaAspect = (slide) => {
            if (slide.tagName === 'IMG' && slide.complete && slide.naturalWidth !== 0) {
                return slide.naturalWidth / slide.naturalHeight
            } else if (slide.tagName === 'VIDEO' && slide.videoWidth !== 0) {
                return slide.videoWidth / slide.videoHeight
            }
        }

        // Get all slides. For now, this rule will only apply to images.
        const slides = Array.from(this.flickity.element.querySelectorAll('.carousel-cell img') || []).filter(slide =>
            getMediaAspect(slide) !== null
        )

        // Get all loaded landscape slides
        const landscapeSlides = slides.filter(slide => getMediaAspect(slide) > 1)

        if (landscapeSlides.length === 0) return

        // Get the widest slide
        let widest = landscapeSlides[0]
        landscapeSlides.forEach(slide => {
            const aspect = getMediaAspect(slide)
            if (aspect && aspect > getMediaAspect(widest)) {
                widest = slide
            }
        })

        const {height} = widest.getBoundingClientRect()

        // Set each slide height to the height of the widest slide
        slides.forEach(slide => {
            slide.style.height = `${height}px`
        })
    }

	render() {
        const style = css`
            user-select: none;
            margin-top: 120px;
            opacity: ${this.state.slides.find(slide => slide.loaded === true) ? 1 : 0};
            transition: opacity 0.3s;
            @media(max-width: 500px) {
                margin-top: 40px;
            }
            & .preloading-slides {
                height: 0;
                overflow: hidden;
            }
            & .carousel {
                outline: none !important;
                user-select: none;
                & .flickity-viewport {
                    position: relative;
                    width: 100vw;
                }
                & .carousel-cell {
                    position: relative;
                    transition: opacity 0.25s;
                    opacity: 0.5;
                    margin: 0 20px;
                    @media (max-width: 500px) {
                        margin: 0 16px;
                        max-width: calc(100vw + 1px);
                        max-height: 80vh;
                    }
                    > img, video {
                        width: auto;
                        height: auto;
                        max-height: 900px;
                        max-width: calc(100vw - 240px);
                        display: block;
                        @media (max-width: 1399px) {
                            max-height: 750px;
                        }
                        @media (max-width: 500px) {
                            max-width: calc(100vw + 1px);
                            max-height: ${innerHeight() * .7}px;
                        }
                    }
                }
                & carousel-cell.preloading {
                    position: absolute;
                    height: 0;
                    width: 0;
                    overflow: hidden;
                }
                & .carousel-cell.is-selected {
                    opacity: 1;
                    transition: none;
                }
                & .flickity-slider {
                    height: 100%;
                }
                & .flickity-page-dots {
                    display: flex;
                    counter-reset: item;
                    list-style-type: none;
                    margin: 32px 40px 0 0;
                    max-width: 1200px;
                    position: relative;
                    left: 0;
                    right: 0;
                    margin-left: auto;
                    margin-right: auto;
                    padding-left: 0;
                    @media (max-width: 1280px) {
                        margin-left: 40px;
                    }
                    > li {
                        font-size: 11px;
                        width: 24px;
                        padding: 4px;
                        opacity: 0.5;
                        cursor: pointer;
                        transition: opacity 0.1s;
                        @media (max-width: 500px) {
                            width: 28px;
                        }
                    }
                    ${ this.state.slides.map( (slide, index) => {
                        if (slide.loaded) {
                            return null
                        } else {
                            return `
                                & li:nth-child(${index + 1}) {
                                    display: none;
                                }
                            `
                        }
                    }).join(' ') }
                    & .is-selected {
                        opacity: 1;
                    }
                    & li:before {
                        content: counter(item) "  ";
                        counter-increment: item;
                    }
                    @media (max-width: 500px) {
                        margin: 20px 20px 0 0;
                        padding-left: 20px;
                        justify-content: center;
                        > li.dot {
                            background: black;
                            padding: 0;
                            width: 4px;
                            height: 4px;
                            margin: 0 6px;
                            border-radius: 3px;
                            opacity: 0.3 !important;
                        }
                        & li.is-selected {
                            opacity: 0.7 !important;
                        }
                        > li:before {
                            display: none;
                        }
                    }
                }
                & .flickity-page-dots:hover {
                    > li {
                        opacity: 0.6;
                    }
                    > li:hover {
                        opacity: 1;
                    }
                }
            }
            > footer {
                margin: -20px 0 0 0;
                min-height: 17px;
                font-size: 11px;
                text-align: right;
                display: flex;
                justify-content: center;
                // opacity: ${this.state.slides.find(slide => slide.loaded === true) ? 1 : 0};
                @media (max-width: 500px) {
                    margin: 20px;
                }
                > .caption {
                    text-transform: uppercase;
                    letter-spacing: 0.15em;
                    padding-top: 4px;
                    max-width: 1200px;
                    margin-right: 40px;
                    margin-left: 40px;
                    width: 100%;
                }
                > .caption.mobile {
                    text-align: center;
                    margin-top: 0;
                    margin-left: 0;
                    margin-right: 0;
                    hyphens: auto;
                }
            }

        `

        const flickityOptions = {
            prevNextButtons: false,
            percentPosition: true,
            wrapAround: true,
            draggable: window.innerWidth < 500,
            resize: true,
            dragThreshold: 4,
            friction: 0.6,
            selectedAttraction: 0.08,
            cellSelector: '.carousel-cell',
            on: {
                select: this.onSlideSelect,
                dragStart: this.onDragStart,
                dragEnd: this.onDragEnd
            }
        }

        const isMobile = window.matchMedia('(max-width: 500px)').matches

		return (
			<section class={ style } ref={section => this.section = section}>
                {isMobile && (
                    <footer>
                        <div class="caption mobile" dangerouslySetInnerHTML={{__html:(this.props.caption || '').replace(/<a href=/g, '<a target="_blank" href=')}}>
                        </div>
                    </footer>
                )}
                <div
                    class="carousel"
                    onclick={e => this.onClick(e)}
                    onmousemove={ e => this.onMouseMove(e)}
                >
                    <Flickity
                        options={ flickityOptions }
                        flickityRef={ flickity => this.flickity = flickity }
                    >
                        {this.state.slides.map( (slide, index) => (
                            <CarouselSlide
                                {...slide}
                                slide={slide}
                                class={[
                                    'carousel-cell',
                                    slide.loaded ? '' : 'preloading',
                                    this.flickity && this.flickity.activeIndex === index ? 'is-selected' : ''
                                ].join(' ')}
                                onload={ slide.loaded ? null : (e) => this.onSlideLoad(e, slide) }
                                muted={this.state.muted}
                                onMuteButtonClick={this.onMuteButtonClick}
                            >
                                { slide.type === 'image' ?
                                    <img
                                        src={slide.url}
                                        alt={slide.caption}
                                    />
                                :
                                    <video
                                        alt={slide.caption}
                                        src={slide.url}
                                        preload="auto"
                                        playsinline
                                        loop
                                        autoplay
                                    ></video>
                                }
                            </CarouselSlide>
                        ))}
                    </Flickity>
                </div>
                {!isMobile && (
                    <footer>
                        <div class="caption" dangerouslySetInnerHTML={{__html:(this.props.caption || '').replace(/<a href=/g, '<a target="_blank" href=')}}>
                        </div>
                    </footer>
                )}
			</section>
		)
	}
}
