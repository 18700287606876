import { h, Component } from 'preact'
import { css } from 'emotion'

const squareButtonStyle = css`
    text-decoration: none;
    > button {
        font-family: MicrogrammaStd-BoldExtended;
        background: transparent;
        font-size: 11px;
        letter-spacing: 1px;
        height: 30px;
        padding: 0px 11px;
        margin: 0 5px;
        border: 1px solid black;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
        user-select: none;
        &:active {
            background: black;
            color: white;
            -webkit-font-smoothing: antialiased;
        }
    }
`

export default function SquareButton({children, className, href, onclick, target}) {
    return (
        <a href={href || null} class={`${squareButtonStyle} ${className || ''}`} target={target || ''} >
            <button onclick={ onclick ? (e) => onclick(e) : null }>
                {children}
            </button>
        </a>
    )
}
