import { h, Component } from 'preact'
import { css } from 'emotion'
import utils from './utils'
import Project from './Project'

export default class Projects extends Component {
    constructor(props) {
        super(props)

        this.state.projectOpen = this.props.projectOpen
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            projectOpen: nextProps.projectOpen
        })

        // If more projects have been loaded, keep site scrolled to same project
        if (this.props.projects.length !== nextProps.projects.length) {
            this.setState({
                animateScroll: false
            })
        } else {
            this.setState({
                animateScroll: true
            })
        }
    }

    closeProject = () => {
        this.setState({
            projectOpen: false
        })
    }

	render() {
        const projectsStyle = css`
            width: 100%;
            min-height: 100%;
            transition: transform ${ this.state.animateScroll ? 0.8 : 0 }s ease-in-out;
            transform: translateY(${ this.props.activeProject.index * - (100 / this.props.projects.length) }%);
            & .click-blocker {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                cursor: pointer;
            }
        `

		return (
			<div class={ projectsStyle }>
                { this.props.projects.map( (project, index) =>
                    <Project
                        project={ project }
                        content={this.props.content}
                        isOpen={ this.state.projectOpen }
                        isActive={ this.props.activeProject === project }
                        panelIsActive={ this.props.panelIsActive }
                        onXButtonClick={ this.closeProject }
                        onscroll={ this.props.onProjectScroll }
                        onload={ this.props.onFirstProjectLoad }
                        onFirstProjectLoad={ this.props.activeProject === project ? this.onFirstProjectLoad : null }
                    />
                ) }
                { this.props.clicksBlocked ?
                    <div class="click-blocker" onClick={ this.props.onBlockedClick }></div>
                : null}
			</div>
		)
	}
}
